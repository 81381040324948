










// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import LoginComponent from '@/components/Login.vue';

@Component({
    components: {
        LoginComponent,
    },
})
class Login extends Vue {}

export default Login;
