import { render, staticRenderFns } from "./ClaimBox.vue?vue&type=template&id=32929934&scoped=true&"
import script from "./ClaimBox.vue?vue&type=script&lang=ts&"
export * from "./ClaimBox.vue?vue&type=script&lang=ts&"
import style0 from "./ClaimBox.vue?vue&type=style&index=0&id=32929934&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32929934",
  null
  
)

export default component.exports