




























































































































































































































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import VeriTiseText from '@/components/VeriTiseText.vue';
import axios from 'axios';
import ShowTransactionModal from '@/components/transactions/ShowTransactionModal.vue';
import ShowRefundModal from '@/components/transactions/ShowRefundModal.vue';
import { ITransfersParams, TransferItem } from '../../../sharedTypes/Transfers';
import { DECIMALS } from '../../../constants';
import Decimals from 'decimal.js';
import Decimal from 'decimal.js';
import { DepositRequest, DepositRequest_Filled } from '../../../sharedTypes';

@Component({
    components: {
        VeriTiseText,
        ShowTransactionModal,
        ShowRefundModal,
    },
})
export default class TransactionsTable extends Vue {
    private loading = false;
    private filter: ITransfersParams = {
        status: 'all',
        type: 'all',
        order_by: 'newest',
        search_text: '',
    };
    private searchRes: TransferItem[] = [];
    transaction: TransferItem | null = null;
    private hasMore = false;

    async search(merge = false) {
        this.loading = true;

        if (!merge) {
            this.filter.last_deposit_id = undefined;
        }

        try {
            const idToken = await firebase.auth().currentUser!.getIdToken();
            const searchRes = await axios.get(`/backendApis/backend/transfers`, {
                params: this.filter,
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            });

            this.hasMore = searchRes.data.hasMore;

            const transfers = searchRes.data.transfers;

            if (merge) {
                this.searchRes = [...this.searchRes, ...transfers];
            } else {
                this.searchRes = transfers;
            }

            this.loading = false;
        } catch (e) {
            this.loading = false;
        }
    }

    async mounted() {
        await this.search();
    }

    prepareCrossLadderInfo(data: DepositRequest | DepositRequest_Filled | undefined | null) {
        if (!data?.crossLadderInfo) {
            return [];
        }

        return data.crossLadderInfo.map(cross => ({
            ...cross,
            price:
                Math.round(
                    cross.price * (1 - ('discount' in data ? data?.discount ?? 0 : 0)) * 100
                ) / 100,
        }));
    }

    async showTransactionModal(tx) {
        this.transaction = tx;
        this.$refs.txmodal.modalShow();
    }

    async showRefundModal(tx) {
        this.transaction = tx;
        this.$refs.refund_modal.modalShow();
    }

    async closeModal() {
        this.transaction = null;
    }

    roundAmount(amount: number | string | undefined, token: keyof typeof DECIMALS) {
        return new Decimals(amount ?? 0)
            .toDecimalPlaces(
                DECIMALS[token],
                token === 'vts' ? Decimal.ROUND_UP : Decimal.ROUND_HALF_UP
            )
            .toString();
    }

    async loadMore() {
        if (!this.hasMore) {
            return;
        }

        const lastTx = this.searchRes?.length ? this.searchRes[this.searchRes.length - 1] : null;

        if (lastTx) {
            this.filter.last_deposit_id = lastTx.transaction_id;
            await this.search(true);
        }
    }
}
