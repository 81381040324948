






// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import WalletsTable from '@/components/wallets/WalletsTable.vue';
// eslint-disable-next-line camelcase

@Component({
    components: {
        WalletsTable,
    },
})
class Wallets extends Vue {}

export default Wallets;
