






















































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import VeriTiseText from '@/components/VeriTiseText.vue';
import axios from 'axios';
// eslint-disable-next-line camelcase

@Component({
    components: {
        VeriTiseText,
    },
})
export default class Dashboard extends Vue {
    private searchText = '';
    private searching = false;
    private searchRes: {
        amount: number;
        displayName: string;
        email: string;
        kyc: 'completed' | 'never-started' | 'cancelled';
        uID: string;
    }[] = [];
    private unsubs: any[] = [];
    private results: any[] = [];

    async search() {
        this.searching = true;

        const idToken = await firebase.auth().currentUser!.getIdToken();
        this.searchRes = await axios
            .post(
                `/backendApis/backend/search-user?search=${this.searchText}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                }
            )
            .then(r => r.data);
        console.log(this.searchRes);
        this.searching = false;
    }

    async downloadCSV() {
        const idToken = await firebase.auth().currentUser!.getIdToken();
        const res = await axios.get('/backendApis/backend/csv', {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });
        const url = window.URL.createObjectURL(await res.blob());
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'vt-users.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }
}
