


























































import Vue from 'vue';
import Component from 'vue-class-component';
import VeriTiseText from '@/components/VeriTiseText.vue';
import { EventBus } from '@/utils/bus';
import firebase from 'firebase';

@Component({
    components: { VeriTiseText },
})
@Component({
    computed: {
        isLoggedin() {
            return this.$store.state.isLogin;
        },
    },
})
export default class Comp extends Vue {
    join() {
        if (this.isLoggedin) {
            this.$router.push({ path: `/dashboard/${firebase.auth().currentUser!.uid}` });
        } else {
            EventBus.$emit('showSignUpModal');
        }
    }
}
