



































































































import Vue from 'vue';
import moment from 'moment';
import Component from 'vue-class-component';
import VeriTiseText from '@/components/VeriTiseText.vue';
import firebase from 'firebase';
import { Refs } from '@/utils/refs';
import { UserDataWriteProtected } from '@/sharedTypes';
import { KYC } from '@/sharedTypes/userData';
declare var bootstrap: any;

@Component({
    props: {
        userID: String,
    },
    components: { VeriTiseText },
})
export default class KYCBox extends Vue {
    private kycDataUrl: any = null;
    private userData: UserDataWriteProtected | null = null;
    private unsubs: any[] = [];
    private kycData: KYC | null = null;
    private isKycInitting = false;
    private shuftyModal = false;
    modal: any;

    inBackoff(): boolean {
        if (this.kycData === null) {
            return false;
        }

        return (
            this.kycData.reason === 'backoff' &&
            this.kycData.startedAt + 3600 * 4 > moment.utc().unix()
        );
    }

    backoffExpiration(): string {
        if (this.inBackoff()) {
            return moment(moment.unix(this.kycData!.startedAt))
                .add(4, 'hours')
                .format('YYYY-MM-DD HH:mm:ss');
        }

        return '';
    }

    justStarted(): boolean {
        if (this.kycData === null) {
            return false;
        }

        return this.kycData.startedAt + 3600 * 2 > moment.utc().unix();
    }

    async mounted() {
        this.modal = new bootstrap.Modal(this.$refs.shuftyModal, {
            backdrop: 'static',
            keyboard: false,
        });
        // this.$emit('showSuccess');

        console.log(this.userID);

        const userRefs = Refs.forUser(this.userID!);
        const r = userRefs.wp.data;
        this.unsubs.push(
            r.onSnapshot(next => {
                this.userData = next.data()!;

                this.$forceUpdate();
            })
        );
        this.unsubs.push(
            userRefs.wp.kyc.main.onSnapshot(next => {
                this.kycData = next.data()!;
                this.$forceUpdate();

                if (this.isKycInitting) {
                    this.kycDataUrl = this.kycData.url;
                }

                this.isKycInitting = false;
            })
        );
    }

    destroyed() {
        this.unsubs.forEach(a => a());
    }

    async showShaftyModal() {
        this.modal.show();
    }

    async closeModal() {
        this.modal.hide();
    }

    async initKYC() {
        this.showShaftyModal();
        this.isKycInitting = true;

        const doc = Refs.forUser(firebase.auth().currentUser!.uid!).wp.kyc.requests.doc();
        await doc.set({
            status: 'pending',
        });
    }

    continueKYC() {
        this.showShaftyModal();
        this.isKycInitting = true;
        this.kycDataUrl = this.kycData!.url!;
    }
}
