













import Vue from 'vue';
import Component from 'vue-class-component';

declare var bootstrap: any;

@Component({
    props: {
        append: String,
        onCopy: Function,
    },
})
export default class CopyTextComponent extends Vue {
    private tt;
    append: any;
    appendText: '' | undefined;

    mounted() {
        const btnEl: any = this.$refs['copyBtn' + this.$vnode.key];
        this.tt = new bootstrap.Tooltip(btnEl, { trigger: 'manual' });

        if (this.append) {
            this.appendText = this.append;
        }
    }

    copy() {
        const el: any | undefined = this.$slots.default![0].elm;

        if (typeof el === 'undefined') {
            return;
        }

        const wasDisabled = !!el.getAttribute('disabled');

        if (wasDisabled) {
            el.removeAttribute('disabled');
        }

        if (this.appendText) {
            el.value = el.value.substring(0, el.value.length - this.appendText.length - 1);
        }

        el.select();
        el.setSelectionRange(0, 99999);

        if (wasDisabled) {
            el.setAttribute('disabled', 'disabled');
        }

        const res = document.execCommand('copy');
        const sel = window.getSelection();

        if (this.appendText) {
            el.value = el.value + ' ' + this.appendText.toUpperCase();
        }

        if (sel !== null) {
            sel.removeAllRanges();
        }

        if (res) {
            this.tt.show();
            setTimeout(() => this.tt.hide(), 2000);
        }

        if (this.$props.onCopy) {
            this.$props.onCopy();
        }
    }
}
