
import Vue from 'vue';
import Component from 'vue-class-component';
import axios from 'axios';

@Component({
    name: 'FailPage',
    components: {},
})
export default class FailPage extends Vue {
    depositId?: string = '';
    user_id?: string = '';

    async mounted() {
        this.depositId = (this.$route.query.ac_order_id as string | undefined) || '';
        this.user_id = (this.$route.query.user_id as string | undefined) || '';
        console.log(this.depositId, this.user_id);
        await axios
            .get(
                `/advCashHandlers/payment/fail?ac_order_id=${this.depositId}&user_id=${this.user_id}`
            )
            .then(() => {
                this.$router.push(`/dashboard/${this.user_id}`);
            })
            .catch(() => {
                this.$router.push(`/dashboard/${this.user_id}`);
            });

        //     const userDepositRef = Refs.userDeposits(this.user_id,this.depositId)
        //     const allDepositRef = Refs.privDeposits.all.doc()
        //     userDepositRef.set({ transactionId: '' }, { merge: true })
        //     // const userDepositRef = firebase
        //     //     .firestore()
        //     //     .doc(`users/${this.user_id}/deposit/${this.depositId}`);
        //     //const allDepositRef = firebase.firestore().doc(`_priv/deposits/all/${this.depositId}`);
        //     const pendingDepositRef = firebase
        //         .firestore()
        //         .doc(`_priv/deposits/pending/${this.depositId}`);

        //     await firebase.firestore().runTransaction(async transaction => {
        //         await transaction.set(userDepositRef, { transactionId: '' }, { merge: true });
        //         await transaction.set(allDepositRef, { transactionId: '' }, { merge: true });
        //         await transaction.set(pendingDepositRef, { transactionId: '' }, { merge: true });
        //     });
        // } catch(err) {
        //     console.log("err",err)
        // }
        // this.$router.push(`/dashboard/${this.user_id}`);
    }
}
