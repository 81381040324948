






// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import WithdrawsTable from '@/components/withdraws/WithdrawsTable.vue';
// eslint-disable-next-line camelcase

@Component({
    components: {
        WithdrawsTable,
    },
})
class Dashboard extends Vue {}

export default Dashboard;
