










































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import { EventBus } from '@/utils/bus';
import axios from 'axios';
import firebase from 'firebase';

declare var bootstrap;

@Component({
    watch: {
        country(val: string) {
            console.log(val);
        },
    },
})
export default class CountryModal extends Vue {
    private country = '';
    private loading = false;

    modal: any;

    mounted() {
        this.modal = new bootstrap.Modal(this.$refs.countrymodal, {
            backdrop: 'static',
            keyboard: false,
        });
        EventBus.$on('showSetCountryModal', () => {
            this.openModal();
        });
    }

    async saveCountry() {
        this.loading = true;

        const idToken = await firebase.auth().currentUser!.getIdToken();
        const userId = firebase.auth().currentUser!.uid;
        axios
            .post(
                `/apis/set-country/${userId}/${this.country}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                }
            )
            .then(r => {
                if (!r.data.error) {
                    this.closeModal();
                    window.location.reload();
                }

                console.log(r.data.reason);
            })
            .catch(e => {
                alert(e.message);
            })
            .finally(() => (this.loading = false));
    }

    async setLoading(v) {
        this.loading = v;
    }

    openModal() {
        this.modal.show();
    }

    async closeModal() {
        this.modal.hide();
    }
}
