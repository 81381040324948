









































import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import axios from 'axios';

@Component({
    components: {},
})
export default class ScanStep extends Vue {
    private error = false;
    private secret = '';
    private qrcodeImg = '';
    async mounted() {
        this.$emit('changeLoading', true);

        const uid = firebase.auth().currentUser!.uid;
        const idToken = await firebase.auth().currentUser!.getIdToken();
        const searchRes = await axios.get(`/twofactorauth/generate-qr/${uid}`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });

        if (searchRes.data.qrcode) {
            this.secret = searchRes.data.secret;
            this.$emit('setSecret', this.secret);
            this.qrcodeImg = searchRes.data.qrcode;
        } else {
            this.secret = '';
        }

        this.$emit('changeLoading', false);
    }
}
