




















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import { CurLadder } from '@/sharedTypes';
import { Refs } from '@/utils/refs';
import { EventBus } from '@/utils/bus';

@Component({})
export default class Comp extends Vue {
    private ladder: any = null;
    private curLadder: CurLadder | null = null;

    private unsubs: any[] = [];

    async mounted() {
        this.unsubs.push(
            Refs.stats('ladder').onSnapshot(next => {
                this.ladder = next.data()!;
                this.$forceUpdate();
            })
        );
        this.unsubs.push(
            Refs.info('curLadderInfo').onSnapshot(next => {
                this.curLadder = next.data()!;
                this.$forceUpdate();
            })
        );
    }

    destroyed() {
        this.unsubs.forEach(a => a());
    }

    clickBuy() {
        this.$analytics.logEvent('LandingCurrentTierButtonBuy');

        if (firebase.auth().currentUser !== null) {
            this.$router.push({ path: `/dashboard/${firebase.auth().currentUser!.uid}` });
        } else {
            EventBus.$emit('showSignInModal');
        }
    }

    clickReadMore() {
        // TODO: ??
    }

    status(what) {
        if (what.statusClass === 'sold') {
            return this.$t('pricingLadder.sold_out');
        } else if (what.statusClass === 'available') {
            return this.$t('pricingLadder.available');
        } else if (what.statusClass === 'reserved') {
            return this.$t('pricingLadder.reserved');
        }

        return this.$t('pricingLadder.locked');
    }

    desc(what) {
        if (what.statusClass === 'sold') {
            return 'Sold';
        } else if (what.statusClass === 'available') {
            return 'VeriTise available';
        }

        return '...';
    }
}
