import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import firebase from 'firebase';

Vue.use(VueRouter);

const authGuard = (to, from, next) => {
    if (firebase.auth().currentUser === null) {
        return next('/');
    }

    next();
};

const adminGuard = (to, from, next) => {
    const user = firebase.auth().currentUser;

    if (!user) {
        return next('/');
    }

    user.getIdTokenResult()
        .then(idTokenResult => {
            if (idTokenResult.claims.admin) {
                return next();
            } else {
                return next('/');
            }
        })
        .catch(() => next('/'));
};

const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue' as any),
    },
    {
        path: '/backend',
        name: 'Backend',
        beforeEnter: adminGuard,
        component: () => import('../views/Backend.vue' as any),
    },
    {
        path: '/transactions',
        name: 'Transactions',
        beforeEnter: adminGuard,
        component: () => import('../views/Transactions.vue' as any),
    },
    {
        path: '/withdraws',
        name: 'Withdraws',
        beforeEnter: adminGuard,
        component: () => import('../views/Withdraws.vue' as any),
    },
    {
        path: '/wallets',
        name: 'Wallets',
        beforeEnter: adminGuard,
        component: () => import('../views/Wallets.vue' as any),
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue' as any),
    },
    {
        path: '/token-sale-terms-and-conditions',
        name: 'Terms',
        component: () => import('../views/Terms.vue' as any),
    },
    {
        path: '/reset-password',
        name: 'Reset Password',
        component: () => import('../views/ResetPassword.vue' as any),
    },
    {
        path: '/actions',
        name: 'Actions page',
        component: () => import('../views/Actions.vue' as any),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue' as any),
    },
    {
        path: '/dashboard/:userID',
        name: 'Dashboard',
        props: true,
        beforeEnter: authGuard,
        component: () => import('../views/Dashboard.vue' as any),
    },
    {
        path: '/settings',
        name: 'Settings',
        props: true,
        beforeEnter: authGuard,
        component: () => import('../views/Settings.vue' as any),
    },
    {
        path: '/settings/2fa',
        name: 'TwoFASteps',
        props: { isSetupFlow: true },
        beforeEnter: authGuard,
        component: () => import('../views/TwoFASteps.vue'),
    },
    {
        path: '/completeTransaction/:txID',
        name: 'Complete Transaction',
        beforeEnter: authGuard,
        component: () => import('../views/CompleteTransaction.vue' as any),
    },
    {
        path: '/sold',
        name: 'All Sold',
        beforeEnter: authGuard,
        component: () => import('../views/AllSold.vue' as any),
    },
    {
        path: '/ref/:referrer',
        name: 'Referral Redirect',
        component: () => import('../views/Referral.vue' as any),
    },
    {
        path: '/claim',
        name: 'Claim Tokens',
        beforeEnter: authGuard,
        component: () => import('../views/ClaimRiseTokens.vue' as any),
    },
    {
        path: '/payment/success',
        name: 'Success',
        beforeEnter: authGuard,
        component: () => import('../views/success.vue' as any),
    },
    {
        path: '/payment/fail',
        name: 'Fail',
        beforeEnter: authGuard,
        component: () => import('../views/fail.vue' as any),
    },
    {
        path: '/payment/status',
        name: 'Status',
        beforeEnter: authGuard,
        component: () => import('../views/status.vue' as any),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (!(window as any).commonUtils.auth.route(to.fullPath)) {
        alert('Forbidden');
    }

    if (!(window as any).commonUtils.auth.meta(to.meta.AuthLevel)) {
        alert('Forbidden');
    }

    next();
});

export default router;
