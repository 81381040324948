





























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    components: {},
    props: {
        current: Number,
    },
})
export default class SetupStepperBar extends Vue {}
