



































import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';

@Component({
    props: {
        ladder: String,
        title: String,
    },
})
export default class Top10 extends Vue {
    private ladder;
    private ladderData = [];
    private title;
    async mounted() {
        const yo = await firebase.firestore().collection('stats').doc(this.ladder).get();
        this.ladderData = Object.values(yo.data() as any);
    }
}
