


































































































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import CheckStep from '@/components/settings/2fa/CheckStep.vue';
import DoneStep from '@/components/settings/2fa/DoneStep.vue';
import InstallStep from '@/components/settings/2fa/InstallStep.vue';
import SaveKeyStep from '@/components/settings/2fa/SaveKeyStep.vue';
import ScanStep from '@/components/settings/2fa/ScanStep.vue';
import SetupStepperBar from '@/components/settings/2fa/SetupStepperBar.vue';
// eslint-disable-next-line camelcase

@Component({
    components: {
        CheckStep,
        DoneStep,
        InstallStep,
        SaveKeyStep,
        ScanStep,
        SetupStepperBar,
    },
})
export default class TwoFASteps extends Vue {
    private isSetupFlow = this.$route.params.isSetupFlow;
    private loading = false;
    private currentStep = '';
    private secret = '';
    private steps = ['install', 'scan', 'save-key', 'check', 'done'];

    async mounted() {
        if (!this.isSetupFlow) {
            this.steps = ['check', 'done'];
        }

        this.currentStep = this.steps[0];
    }

    async prevStep() {
        this.loading = false;

        if (!this.isSetupFlow) {
            await this.$router.push('/settings');
            return null;
        }

        this.currentStep = this.steps[this.currentStepIndex - 1];
        return null;
    }

    async backToSettings() {
        return null;
    }

    async nextStep() {
        this.currentStep = this.steps[this.currentStepIndex + 1];
        return null;
    }

    async changeLoading(val) {
        this.loading = val;
    }

    async setSecret(secret) {
        this.secret = secret;
    }

    get showBackBtn() {
        if (!this.isSetupFlow) {
            return this.currentStep === 'check';
        }

        return this.currentStep !== 'install' && this.currentStep !== 'done';
    }

    get showBackToSettingsBtn() {
        return this.currentStep === 'done';
    }

    get showContinue() {
        return this.currentStep !== 'done';
    }

    get currentStepIndex() {
        return this.steps.indexOf(this.currentStep);
    }
}
