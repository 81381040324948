







































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import PricingLadder from '../components/home/PricingLadder.vue';
import Hero from '../components/home/Hero.vue';
import Tokenomics from '../components/home/Tokenomics.vue';
import Top10 from '../components/home/Top10.vue';
import AffiliateBoxInfo from '@/components/home/AffiliateBoxInfo.vue';
import StatsAndInfos from '@/components/home/StatsAndInfos.vue';
import TotalSalesSupply from '@/components/home/TotalSalesSupply.vue';
import LoginComponent from '@/components/Login.vue';
import NewsList from '@/components/home/NewsList.vue';

@Component({
    components: {
        NewsList,
        TotalSalesSupply,
        AffiliateBoxInfo,
        StatsAndInfos,
        Top10,
        PricingLadder,
        Hero,
        LoginComponent,
        Tokenomics,
    },
})
export default class Home extends Vue {}
