







// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import SearchDb from '@/components/backend/SearchDb.vue';
import PendingWireTransfers from '@/components/backend/PendingWireTransfers.vue';
// eslint-disable-next-line camelcase

@Component({
    components: {
        PendingWireTransfers,
        SearchDb,
    },
})
class Dashboard extends Vue {}

export default Dashboard;
