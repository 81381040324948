import Decimal from 'decimal.js';
import { ILadder } from '../../sharedTypes';
import { CURRENT_LADDER_TOTAL_AMOUNT } from '../../functions/src/config';
import { DECIMALS } from '../../constants';
import { ICrossLadderInfo } from '../../sharedTypes/crossLadderInfo';

export interface ICrossLadderVeriTisePrices {
    ladderIndex: number;
    amountVeriTise: Decimal;
    price: number;
    fiatAmount: Decimal;
    amountVeriTiseFromDiscount?: Decimal;
}

export interface ICrossLadderAmounts {
    remaining: number;
    price: number;
    index: number;
}

export const getCrossLadderVeriTiesPrices = (
    amountVeriTise: number,
    laddersData: Record<string, ICrossLadderAmounts>,
    ladderIndex: number,
    isDiscount = false
): ICrossLadderVeriTisePrices[] => {
    const currentLadder = laddersData[ladderIndex];

    if (!currentLadder) {
        return [];
    }

    const currentLadderRemaining = currentLadder?.remaining ?? CURRENT_LADDER_TOTAL_AMOUNT;

    if (currentLadderRemaining === 0) {
        return getCrossLadderVeriTiesPrices(
            amountVeriTise,
            laddersData,
            ladderIndex + 1,
            isDiscount
        );
    }

    if (currentLadderRemaining >= amountVeriTise) {
        return [
            {
                ladderIndex: currentLadder.index,
                price: currentLadder.price,
                fiatAmount: new Decimal(isDiscount ? 0 : amountVeriTise).mul(currentLadder.price),
                amountVeriTise: new Decimal(amountVeriTise),
            },
        ];
    } else {
        const currentTierAmount = new Decimal(isDiscount ? 0 : currentLadderRemaining).mul(
            currentLadder.price
        );
        const restAmount = amountVeriTise - currentLadderRemaining;

        return [
            {
                ladderIndex: currentLadder.index,
                price: currentLadder.price,
                fiatAmount: currentTierAmount,
                amountVeriTise: new Decimal(currentLadderRemaining),
            },
            ...getCrossLadderVeriTiesPrices(restAmount, laddersData, ladderIndex + 1, isDiscount),
        ];
    }
};

export const getUpdatedLadders = (
    laddersData: Record<string, ILadder>,
    crossLadderVeriTiesPrices: ICrossLadderVeriTisePrices[]
): Record<string, ILadder> => {
    const ladders: Record<string, ILadder> = {
        ...laddersData,
        ...crossLadderVeriTiesPrices
            .map<ILadder>(cross => {
                const ladder = laddersData[cross.ladderIndex];
                const remaining = cross.amountVeriTise.sub(ladder.remaining).greaterThanOrEqualTo(0)
                    ? 0
                    : new Decimal(ladder.remaining ?? CURRENT_LADDER_TOTAL_AMOUNT)
                          .sub(cross.amountVeriTise)
                          .ceil()
                          .toNumber();
                let percentage = remaining
                    ? new Decimal(CURRENT_LADDER_TOTAL_AMOUNT)
                          .sub(remaining)
                          .dividedBy(CURRENT_LADDER_TOTAL_AMOUNT)
                          .mul(100)
                          .toDecimalPlaces(DECIMALS.percent, Decimal.ROUND_CEIL)
                          .toNumber()
                    : 100;
                percentage = percentage === 100 && remaining > 1 ? 99.99 : percentage;

                const statusClass = percentage === 100 ? 'reserved' : 'available';

                return {
                    ...ladder,
                    remaining: percentage === 100 ? 0 : remaining,
                    percentage,
                    statusClass,
                };
            })
            .reduce<Record<number, ILadder>>(
                (acc, cur) => ({
                    ...acc,
                    [cur.index]: cur,
                }),
                {}
            ),
    };

    const hasAvailable = Object.values(ladders).some(ladder => ladder.statusClass === 'available');

    if (!hasAvailable) {
        const sortedLadders = Object.values(ladders)
            .sort((a, b) => a.index - b.index)
            .filter(
                ladder =>
                    ladder.statusClass !== 'reserved' &&
                    ladder.statusClass !== 'sold' &&
                    ladder.remaining > 0
            );

        if (sortedLadders.length) {
            ladders[sortedLadders[0].index].statusClass = 'available';
        }
    }

    return ladders;
};

export const getTotalCrossLadderInfo = (
    additionalInfo: ICrossLadderVeriTisePrices[],
    initialInfo?: ICrossLadderInfo[]
): ICrossLadderVeriTisePrices[] => {
    const mapFn = (item: ICrossLadderInfo) => ({
        ladderIndex: item.ladderIndex,
        price: item.price,
        amountVeriTise: new Decimal(item.amountVeriTise),
        fiatAmount: new Decimal(item.fiatAmount),
        ...(item.amountVeriTiseFromDiscount
            ? {
                  amountVeriTiseFromDiscount: new Decimal(item.amountVeriTiseFromDiscount),
              }
            : {}),
    });

    if (!initialInfo) {
        return additionalInfo;
    }

    const lastInitialInfo = initialInfo[initialInfo.length - 1];
    const restInitialInfo = [...initialInfo];
    restInitialInfo.pop();

    const [firstAdditionalInfo, ...restAdditionalInfo] = additionalInfo;

    if (lastInitialInfo.ladderIndex === firstAdditionalInfo.ladderIndex) {
        const amountVeriTise = firstAdditionalInfo.amountVeriTise.add(
            lastInitialInfo.amountVeriTise
        );
        return [
            ...restInitialInfo.map(mapFn),
            {
                ladderIndex: lastInitialInfo.ladderIndex,
                fiatAmount: firstAdditionalInfo.fiatAmount.add(lastInitialInfo.fiatAmount),
                amountVeriTise: amountVeriTise.greaterThan(CURRENT_LADDER_TOTAL_AMOUNT)
                    ? new Decimal(CURRENT_LADDER_TOTAL_AMOUNT)
                    : amountVeriTise,
                price: lastInitialInfo.price,
                ...(firstAdditionalInfo.fiatAmount.equals(0)
                    ? { amountVeriTiseFromDiscount: firstAdditionalInfo.amountVeriTise }
                    : {}),
                ...(lastInitialInfo.fiatAmount === 0
                    ? { amountVeriTiseFromDiscount: new Decimal(lastInitialInfo.amountVeriTise) }
                    : {}),
            },
            ...restAdditionalInfo,
        ];
    } else {
        return [...initialInfo.map(mapFn), ...additionalInfo];
    }
};
