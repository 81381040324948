










































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    components: {},
})
export default class InstallStep extends Vue {}
