

















































import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import { Refs } from '@/utils/refs';

@Component({
    computed: {
        user() {
            return firebase.auth().currentUser;
        },
    },
})
export default class ClaimBox extends Vue {
    country = '';
    private blackListCountries = [
        'US',
        'LK',
        'LA',
        'FJ',
        'SC',
        'ET',
        'WS',
        'IQ',
        'KP',
        'ZW',
        'AS',
        'IR',
        'KH',
        'YE',
        'PA',
        'VG',
        'BA',
        'VU',
        'PK',
        'VI',
        'BW',
        'TN',
        'UG',
        'GY',
        'BS',
        'TT',
        'OM',
        'GU',
        'AI',
        'SY',
        'MN',
        'GH',
        'AF',
    ];
    async mounted() {
        const uid = await this.user.uid;
        this.country =
            (await Refs.forUser(uid)
                .wp.data.get()
                .then(r => r.data()?.country)) || '';
    }
    isBlacklisted(): boolean {
        return !this.country || (this.country! && this.blackListCountries.includes(this.country!))
            ? true
            : false;
    }
}
