












































































































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import axios from 'axios';
import { DECIMALS } from '../../../constants';
import Decimals from 'decimal.js';
import Decimal from 'decimal.js';
import { queryParam, fieldsOfSystemWallets } from '../../../sharedTypes/privWallet';

@Component({})
export default class TransactionsTable extends Vue {
    private loading = false;
    token = 'btc';
    private filter: queryParam = {
        page: 0,
        token: 'btc',
        used_wallet: 'all',
        order_by: 'asc',
    };
    private searchRes: fieldsOfSystemWallets[] = [];
    private hasMore = false;

    async search(merge = false) {
        this.loading = true;

        if (!merge) {
            this.filter.last_wallet_id = undefined;
        }

        try {
            const idToken = await firebase.auth().currentUser!.getIdToken();
            const searchRes = await axios.get(`/backendApis/backend/tablewallet`, {
                params: {
                    ...this.filter,
                    token: this.token,
                },
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            });

            this.filter.token = this.token;
            this.hasMore = searchRes.data.lenght;

            const wallets = searchRes.data;

            if (merge) {
                this.searchRes = [...this.searchRes, ...wallets];
            } else {
                this.searchRes = wallets;
            }

            this.loading = false;
        } catch (e) {
            this.loading = false;
        }
    }

    async mounted() {
        await this.search();
    }

    roundAmount(amount: number | string | undefined, token: keyof typeof DECIMALS) {
        return new Decimals(amount ?? 0)
            .toDecimalPlaces(
                DECIMALS[token],
                token === 'vts' ? Decimal.ROUND_UP : Decimal.ROUND_HALF_UP
            )
            .toString();
    }

    async loadMore() {
        if (!this.hasMore) {
            return;
        }

        const lastWallet = this.searchRes?.length
            ? this.searchRes[this.searchRes.length - 1]
            : null;

        if (lastWallet) {
            this.filter.last_wallet_id = lastWallet.withdepositAddress;
            await this.search(true);
            this.filter.page += 1;
        }
    }
}
