




































































































































































































































































































































































































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import PricingLadder from '../components/home/PricingLadder.vue';
import Hero from '../components/home/Hero.vue';
import Top10 from '../components/home/Top10.vue';
import StatsAndInfos from '@/components/home/StatsAndInfos.vue';
import TotalSalesSupply from '@/components/home/TotalSalesSupply.vue';
import PhoneLogin from '@/components/PhoneLogin.vue';
import firebase from 'firebase';
import Timer from './Timer.vue';
import { maskEmail } from '@/utils/maskEmail';
import { EventBus } from '@/utils/bus';
import axios from 'axios';
import AuthProvider = firebase.auth.AuthProvider;

declare var bootstrap;

class SignUpLinks {
    readonly privacyPolicyLink = 'https://www.iubenda.com/privacy-policy/11190101';
    readonly termsAndConditionsLink = '/token-sale-terms-and-conditions';

    private getSelectorForLink(link: string) {
        return 'label.form-check-label > a[href="' + link + '"]';
    }

    private privacyPolicyNode: HTMLAnchorElement | null = null;
    private termsNode: HTMLAnchorElement | null = null;

    onClick(callback) {
        this.privacyPolicyNode = document.querySelector(
            this.getSelectorForLink(this.privacyPolicyLink)
        );
        this.termsNode = document.querySelector(
            this.getSelectorForLink(this.termsAndConditionsLink)
        );

        this.privacyPolicyNode?.addEventListener('click', callback);
        this.termsNode?.addEventListener('click', callback);
    }

    removeListener(callback) {
        this.termsNode?.removeEventListener('click', callback);
        this.privacyPolicyNode?.removeEventListener('click', callback);
    }
}

@Component({
    components: {
        PhoneLogin,
        TotalSalesSupply,
        StatsAndInfos,
        Top10,
        PricingLadder,
        Hero,
        Timer,
    },
    watch: {
        country(val: string) {
            if (val) {
                delete this.formErrors.country;
            }
        },
        password(val: string) {
            if (val.length < 6 || val.length > 16) {
                this.formErrors = {
                    ...this.formErrors,
                    password: this.$t('login.password_length'),
                };
                return;
            }
            if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(val)) {
                this.formErrors = {
                    ...this.formErrors,
                    password: this.$t('login.weak_password'),
                };
                return;
            } else {
                delete this.formErrors.password;

                if (!this.isSignIn && val.length) {
                    if (this.password === this.confirm_password) {
                        delete this.formErrors.confirm_password;
                    } else {
                        this.formErrors = {
                            ...this.formErrors,
                            confirm_password: this.$t('login.confirm_password_invalid'),
                        };
                    }
                }
            }

            this.isDirty = true;
        },
        confirm_password(val: string) {
            if (!this.isSignIn) {
                if (this.password.length && val !== this.password) {
                    this.formErrors = {
                        ...this.formErrors,
                        confirm_password: this.$t('login.confirm_password_invalid'),
                    };
                } else {
                    delete this.formErrors.confirm_password;
                }
                this.isDirty = true;
            }
        },
        fa2code(val: string) {
            if (val.length === 0) {
                this.formErrors = {
                    ...this.formErrors,
                    fa2code: this.$t('login.please_enter_valid_code'),
                };
            } else if (val.length > 6 || !/^\d+$/.test(val)) {
                this.formErrors = {
                    ...this.formErrors,
                    fa2code: this.$t('login.please_enter_6digits'),
                };
            } else {
                delete this.formErrors.fa2code;
            }
        },
        termsCheck(val: boolean) {
            if (val) {
                delete this.formErrors.termsCheck;
            }
            this.$analytics.logEvent('LandingSignUpCheckboxTerms&Policy');
        },
        email(val: string) {
            if (val.length === 0) {
                this.formErrors = {
                    ...this.formErrors,
                    email: this.$t('login.please_enter_email'),
                };
            } else if (
                val.length > 100 ||
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(val)
            ) {
                this.formErrors = {
                    ...this.formErrors,
                    email: this.$t('login.email_not_valid'),
                };
            } else {
                delete this.formErrors.email;
            }
        },
        $router() {
            this.closeModal();
        },
    },
})
export default class Login extends Vue {
    private passwordType = 'password';
    private confirmPasswordType = 'password';
    private toggleClass = 'fa-eye';
    private toggleConfirmPasswordClass = 'fa-eye';
    private password = '';
    private confirm_password = '';
    private email = '';
    private errorMessage: null | string = null;
    private termsCheck = false;
    private shouldCheck2fa = false;
    private fa2code = '';
    private country = '';
    private formErrors = {};
    signUpEmail = '';
    isSignIn = false;
    isDirty = false;
    resendAvailable = false;

    phoneCode = false;
    showOtp = false;
    captchaVerified = false;
    phoneLoading = false;
    isPhoneValid = false;
    otpValid = false;

    modal: any;
    signUpLinks: SignUpLinks = new SignUpLinks();

    private gaLogEvents(e: any) {
        const eventName = e.target.href.endsWith(this.signUpLinks.privacyPolicyLink)
            ? 'LandingSignUpLinkPolicy'
            : 'LandingSignUpLinkTerms';

        this.$analytics.logEvent(eventName);
    }

    mounted() {
        this.modal = new bootstrap.Modal(this.$refs.loginmodal, {
            backdrop: 'static',
            keyboard: false,
        });
        EventBus.$on('showSignInModal', () => {
            this.isSignIn = true;
            delete this.formErrors.confirm_password;
            this.openModal();
        });
        EventBus.$on('hideSignInModal', () => {
            this.closeModal();
        });
        EventBus.$on('showSignUpModal', () => {
            this.isSignIn = false;
            this.openModal();
            this.signUpLinks.onClick(this.gaLogEvents);
        });
        EventBus.$on('hideSignUpModal', () => {
            this.closeModal();
        });
    }

    togglePasswordType(target = 'password'): void {
        if (target === 'password') {
            if (this.passwordType === 'password') {
                this.passwordType = 'text';
                this.toggleClass = 'fa-eye-slash';
            } else {
                this.passwordType = 'password';
                this.toggleClass = 'fa-eye';
            }

            return;
        }

        if (this.confirmPasswordType === 'password') {
            this.confirmPasswordType = 'text';
            this.toggleConfirmPasswordClass = 'fa-eye-slash';
        } else {
            this.confirmPasswordType = 'password';
            this.toggleConfirmPasswordClass = 'fa-eye';
        }
    }

    async loginPhoneSuccess(result) {
        console.log('success phone', this.country);
        this.modal.hide();

        try {
            const idToken = await result.user.getIdToken();

            if (!this.isSignIn) {
                await axios.post(
                    `/apis/set-country/${result.user.uid}/${this.country}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${idToken}`,
                        },
                    }
                );
            }

            const is2faEnabled = await this.is2faEnabled(result.user.uid);

            if (!is2faEnabled) {
                this.$router.push({ path: `/dashboard/${result.user.uid}` });
                this.closeModal();
            } else {
                this.modal.show();
                this.shouldCheck2fa = true;
            }

            await Login.processReferral(idToken);
        } catch {
            this.errorMessage = this.$t('login.there_is_a_problem', ['Phone']);
        }
    }

    async setShowOtp(v) {
        this.showOtp = v;
    }

    async setCaptchaVerified(v) {
        this.captchaVerified = v;
    }

    async setPhoneLoading(v) {
        this.phoneLoading = v;
    }

    async setIsPhoneValid(v) {
        this.isPhoneValid = v;
    }

    async setOtpValid(v) {
        this.otpValid = v;
    }

    async loginPhone() {
        // TODO
        await this.validateCountryAndTerms();
        this.phoneCode = true;
    }

    private static async processReferral(idToken?: string) {
        if (localStorage.ref && idToken) {
            await axios
                .post(
                    `/apis/set-referral/${localStorage.ref}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${idToken}`,
                        },
                    }
                )
                .then(r => r.json())
                .catch(console.log);

            localStorage.removeItem('ref');
        }
    }

    private async rawLogin(provider: AuthProvider, source: string) {
        try {
            const res = await firebase.auth().signInWithPopup(provider);

            if (res.user !== null) {
                this.modal.hide();

                const idToken = await res.user.getIdToken();

                if (!this.isSignIn) {
                    await axios.post(
                        `/apis/set-country/${res.user.uid}/${this.country}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${idToken}`,
                            },
                        }
                    );
                }

                const is2faEnabled = await this.is2faEnabled(res.user.uid);

                if (!is2faEnabled) {
                    this.$router.push({ path: `/dashboard/${res.user.uid}` });
                    this.closeModal();
                } else {
                    this.modal.show();
                    this.shouldCheck2fa = true;
                }

                await Login.processReferral(idToken);
            }
        } catch (e) {
            this.errorMessage = this.$t('login.there_is_a_problem', [source]);
        }
    }

    toggleResetAvilable() {
        this.resendAvailable = !this.resendAvailable;
    }

    async loginFacebook(): Promise<void> {
        const facebookAuthEvent = this.isSignIn
            ? 'LandingSignInButtonFacebook'
            : 'LandingSignUpButtonFacebook';
        this.$analytics.logEvent(facebookAuthEvent);
        this.validateCountryAndTerms();

        if (!this.formErrors.country && !this.formErrors.termsCheck) {
            return this.rawLogin(new firebase.auth.FacebookAuthProvider(), 'Facebook');
        }

        return;
    }

    async loginGoogle(): Promise<void> {
        const gaAuthEvent = this.isSignIn
            ? 'LandingSignInButtonGoogle'
            : 'LandingSignUpButtonGoogle';
        this.$analytics.logEvent(gaAuthEvent);
        this.validateCountryAndTerms();

        if (!this.formErrors.country && !this.formErrors.termsCheck) {
            return this.rawLogin(new firebase.auth.GoogleAuthProvider(), 'Google');
        }

        return;
    }

    async validateCountryAndTerms() {
        if (!this.isSignIn && (!this.country || this.country === '' || !this.termsCheck)) {
            if (!this.country) {
                this.formErrors = {
                    ...this.formErrors,
                    country: this.$t('login.pls_select_country'),
                };
            }

            if (!this.termsCheck) {
                this.formErrors = {
                    ...this.formErrors,
                    termsCheck: this.$t('login.agree_terms'),
                };
            }

            return;
        }
    }

    async loginTwitter(): Promise<void> {
        return this.rawLogin(new firebase.auth.TwitterAuthProvider(), 'Twitter');
    }

    async signUp() {
        this.errorMessage = '';
        this.$analytics.logEvent('LandingSignUpButtonWithEmail');

        const country = this.country;

        try {
            if (country || country !== '') {
                const r = await firebase
                    .auth()
                    .createUserWithEmailAndPassword(this.email, this.password);
                await firebase.auth().currentUser?.sendEmailVerification();

                const idToken = await r.user?.getIdToken();
                await Login.processReferral(idToken);

                this.signUpEmail = maskEmail(this.email);
                await axios.post(
                    `/apis/set-country/${r.user?.uid}/${country}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${idToken}`,
                        },
                    }
                );

                await firebase.auth().signOut();
            } else {
                this.errorMessage = 'login.pls_select_country';
            }
        } catch (e) {
            this.errorMessage = e.message;
        }
    }

    async signIn(): Promise<void> {
        this.errorMessage = '';
        this.$analytics.logEvent('LandingSignInButtonWithEmail');

        try {
            const res = await firebase.auth().signInWithEmailAndPassword(this.email, this.password);

            if (
                !!res.user?.emailVerified ||
                res.user?.phoneNumber ||
                res.user?.providerData?.some(item => item?.providerId === 'facebook.com')
            ) {
                const is2faEnabled = await this.is2faEnabled(res.user.uid);

                if (!is2faEnabled) {
                    this.closeModal();
                    this.$router.push(`/dashboard/${res.user.uid}`);
                } else {
                    this.shouldCheck2fa = true;
                }
            } else {
                this.signUpEmail = maskEmail(this.email);
            }
        } catch (e) {
            if (e.code === 'auth/user-not-found') {
                this.errorMessage = this.$t('login.acc_not_found');
                return;
            }

            this.errorMessage = e.message;
        }
    }

    async is2faEnabled(uid) {
        const r = await axios.get(`/twofactorauth/is-enabled/${uid}`);

        return r?.data?.fa2enabled;
    }

    async check2fa() {
        this.$analytics.logEvent('LandingSignInButton2FANext');

        const user = firebase.auth().currentUser;
        const idToken = await firebase.auth().currentUser!.getIdToken();
        const r = await axios.get(`/twofactorauth/check-totp/${this.fa2code}/${user?.uid}`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });

        if (r.data.result) {
            this.shouldCheck2fa = false;
            this.fa2code = '';
            this.closeModal();
            this.$router.push(`/dashboard/${user?.uid}`);
        } else {
            this.formErrors = {
                ...this.formErrors,
                fa2code: this.$t('login.please_enter_valid_code'),
            };
        }
    }

    async resendVerificationCode() {
        try {
            await firebase.auth().signInWithEmailAndPassword(this.email, this.password);
            await firebase.auth().currentUser?.sendEmailVerification();
            await firebase.auth().signOut();
            this.toggleResetAvilable();
        } catch (e) {
            this.errorMessage = e.message;
        }
    }

    forgotPassword() {
        this.$analytics.logEvent('LandingSignInLinkForgotPassword');
        this.$router.push('/reset-password');
        this.closeModal();
    }

    openModal() {
        this.modal.show();
    }

    async closeModal() {
        if (this.shouldCheck2fa) {
            await firebase.auth().signOut();
        }

        const gaModalCloseEvent = this.isSignIn
            ? 'LandingSignInButtonClose'
            : 'LandingSignUpButtonClose';

        this.$analytics.logEvent(gaModalCloseEvent);
        this.signUpLinks.removeListener(this.gaLogEvents);

        this.email = '';
        this.password = '';
        this.confirm_password = '';
        this.errorMessage = '';
        this.formErrors = {};
        this.phoneCode = false;

        this.modal.hide();
    }
}
