






















import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    components: {},
    props: {
        isSetupFlow: Boolean,
    },
})
export default class DoneStep extends Vue {}
