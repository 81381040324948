




































































































































































































































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import VeriTiseText from '@/components/VeriTiseText.vue';
import axios from 'axios';
import { DECIMALS } from '../../../constants';
import Decimals from 'decimal.js';
import Decimal from 'decimal.js';
import { WithdrawRequest_Filled } from '../../../sharedTypes';
import { DepositStatus } from '@/sharedTypes';

declare var bootstrap: any;

@Component({
    components: {
        VeriTiseText,
    },
})
export default class Dashboard extends Vue {
    private searchRes: WithdrawRequest_Filled[] = [];
    private verificationList: any = { verificationList: [] };
    private withdrawalList: any = { withdrawalList: [] };
    private loading = false;
    private unsubs: any[] = [];
    filter = {
        status: 'all',
    };
    modal: any;
    verificationW: WithdrawRequest_Filled | null = null;

    async updateValidation(userId: string) {
        this.loading = true;

        const idToken = await firebase.auth().currentUser!.getIdToken();
        return await axios
            .post(
                `/backendApis/verification/update`,
                {
                    userId,
                    kycVerified: true,
                },
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                }
            )
            .then(() => this.search())
            .catch(e => {
                console.log(e);
            });

        // await this.search();
    }

    async update(item: any, status: DepositStatus) {
        this.loading = true;

        const idToken = await firebase.auth().currentUser!.getIdToken();
        return await axios
            .post(
                `/backendApis/withdraw/update`,
                {
                    climedId: item.txID,
                    userId: item.userID,
                    status,
                    transactionId: item.txID,
                },
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                }
            )
            .then(() => this.search())
            .catch(e => {
                console.log(e);
            });
    }

    async copyText(e) {
        const reference = e.target.innerHTML;
        e.target.innerHTML = '<u>' + this.$t('copied') + '!</u>';
        setTimeout(() => {
            e.target.innerHTML = reference;
        }, 800);
    }

    async mounted() {
        this.modal = new bootstrap.Modal(this.$refs.verificationModal, {
            backdrop: 'static',
            keyboard: false,
        });

        await this.search();
    }

    async search() {
        this.loading = true;

        const idToken = await firebase.auth().currentUser!.getIdToken();
        this.verificationList = await axios
            .get('/backendApis/withdraw/userslist', {
                params: this.filter,
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            })
            .then(r => r.data);
        this.withdrawalList = await axios
            .get('/backendApis/withdraw/list', {
                params: this.filter,
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            })
            .then(r => r.data);
        this.loading = false;
    }

    showVerificationModal(w) {
        this.verificationW = w;
        this.modal.show();
    }

    async closeModal() {
        this.verificationW = null;
        this.modal.hide();
    }

    roundAmount(amount: number | string | undefined, token: keyof typeof DECIMALS) {
        return new Decimals(amount ?? 0)
            .toDecimalPlaces(
                DECIMALS[token],
                token === 'vts' ? Decimal.ROUND_UP : Decimal.ROUND_HALF_UP
            )
            .toString();
    }

    destroyed() {
        this.unsubs.forEach(a => a());
    }
}
