



















import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    props: {
        amounts: Array,
        title: String,
    },
})
export default class RanksInfoBox extends Vue {
    private amounts;
}
