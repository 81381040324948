
























































import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import Timer from './Timer.vue';
import { maskEmail } from '@/utils/maskEmail';

@Component({
    name: 'ResetPasswordForm',
    components: {
        Timer,
    },
    watch: {
        originalEmail(value) {
            this.isDirty = true;

            if (value.length === 0) {
                this.error = this.$t('login.please_enter_email');
            } else if (
                value.length > 100 ||
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)
            ) {
                this.error = this.$t('login.email_not_valid');
            } else {
                this.error = '';
            }
        },
    },
    props: {
        code: String,
    },
})
export default class ResetPasswordForm extends Vue {
    originalEmail = '';
    displayEmail = '';
    success = false;
    submitError = '';
    resendAvailable = false;
    error = '';
    isDirty = false;

    resendCode() {
        this.$analytics.logEvent('PasswordResetLinkResend');
        firebase.auth().sendPasswordResetEmail(this.originalEmail).then(this.toggleResetAvilable);
    }

    toggleResetAvilable() {
        this.resendAvailable = !this.resendAvailable;
    }

    onSubmit() {
        this.$analytics.logEvent('PasswordResetButtonReset');

        firebase
            .auth()
            .sendPasswordResetEmail(this.originalEmail)
            .then(() => {
                this.success = true;
                this.submitError = '';
                this.displayEmail = maskEmail(this.originalEmail);
            })
            .catch(e => {
                this.success = false;

                if (e.code === 'auth/user-not-found') {
                    this.submitError = this.$t('resetPass.if_an_acc');
                    return;
                }

                this.submitError = e;
            });
    }
}
