import '@/utils/common';
import vueCommon from '@/utils/vueCommon';
import http from '@/utils/http';
import i18n from '@/utils/i18n';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import HighchartsVue from 'highcharts-vue';
import firebase from 'firebase';
import moment from 'moment';
import Decimal from 'decimal.js';
import vSelect from 'vue-select';
import { VTooltip } from 'v-tooltip';
import Clipboard from 'v-clipboard';
import axios from 'axios';
import vueCountryRegionSelect from 'vue-country-region-select';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';

enum AppHostNames {
    LOCAL = 'localhost',
    TEST = 'veritise-tsp-test-environment.web.app',
    PRODUCTION = 'tokensale.veritise.com',
}

function configureLibs(host: string) {
    let app: firebase.app.App;

    try {
        switch (host) {
            case AppHostNames.TEST:
                axios.defaults.baseURL =
                    'https://us-central1-veritise-tsp-test-environment.cloudfunctions.net';
                app = firebase.initializeApp({
                    apiKey: 'AIzaSyBZHdtpi8yqV60BqluqaTbhUmonpZKexf0',
                    authDomain: 'veritise-tsp-test-environment.firebaseapp.com',
                    databaseURL: 'https://veritise-tsp-test-environment.firebaseio.com',
                    projectId: 'veritise-tsp-test-environment',
                    storageBucket: 'veritise-tsp-test-environment.appspot.com',
                    messagingSenderId: '482465479746',
                    appId: '1:482465479746:web:300a508de04e03b9df01db',
                });
                break;
            case AppHostNames.LOCAL:
                axios.defaults.baseURL =
                    'http://localhost:5001/veritise-tsp-test-environment/us-central1/';
                app = firebase.initializeApp({
                    apiKey: 'AIzaSyBZHdtpi8yqV60BqluqaTbhUmonpZKexf0',
                    projectId: 'veritise-tsp-test-environment',
                    appId: '1:482465479746:web:300a508de04e03b9df01db',
                });

                firebase.firestore().useEmulator('localhost', 8080);

                firebase.functions().useEmulator('localhost', 5001);

                firebase.auth().useEmulator('http://localhost:9099');
                break;
            case AppHostNames.PRODUCTION:
                axios.defaults.baseURL = 'https://us-central1-risecheck-temp.cloudfunctions.net';
                app = firebase.initializeApp({
                    apiKey: 'AIzaSyACPDjl2SyyAWgdwStm-UrXXKJmzq-qEuI',
                    authDomain: 'tokensale.veritise.com',
                    databaseURL: 'https://risecheck-temp.firebaseio.com',
                    projectId: 'risecheck-temp',
                    storageBucket: 'risecheck-temp.appspot.com',
                    messagingSenderId: '656888929383',
                    appId: '1:656888929383:web:7183c1ffc57cabaa3a08c2',
                    measurementId: 'G-5K30KH8DK8',
                });
                break;

            default:
                throw new Error(`Unknow host for firebase configuration: ${host}`);
        }

        Vue.prototype.$analytics = firebase.analytics(app);
    } catch (e: any) {
        alert(e.message);
    }
}

configureLibs(window.location.hostname);

Vue.use(vueCountryRegionSelect);
Vue.use(HighchartsVue);
Vue.component('v-select', vSelect);
Vue.directive('tooltip', VTooltip);
Vue.use(Clipboard);
Vue.mixin(vueCommon);
Vue.filter('thousands', (n: string | number) => {
    if (typeof n === 'undefined') {
        return '...';
    }

    if (typeof n === 'string') {
        n = parseFloat(n);
    }

    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});
Vue.filter('roundToInt', (n: string | number) =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    Math.round(parseFloat(<string>n))
);

Vue.filter('truncate', (n: number) => Math.floor(n));
Vue.filter('32bitHash', (n: string) => {
    let hash = 0;

    if (n.length === 0) {
        return hash;
    }

    for (let i = 0; i < n.length; i++) {
        const char = n.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash && hash;
    }

    return hash;
});
Vue.filter('formatDate', (n: number) => moment(n * 1000, 'unix').format('YYYY-MM-DD HH:mm:ss'));
Vue.filter('formatDateUtc', (n: number) =>
    moment(n * 1000, 'unix')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
);
Vue.filter('txID', (n: string, howMany = 7) => `${n.substr(0, howMany)} … ${n.substr(-howMany)}`);
Vue.filter('explorerLink', (txID: string, coin: string) => {
    if (coin === 'BTC') {
        return `https://www.blockchain.com/btc/tx/${txID}`;
    } else if (coin === 'ETH' || coin === 'USDt') {
        return `https://ropsten.etherscan.io/tx/${txID}`;
    }

    return '----';
});

Vue.filter('sat', (n: string | number, digits = 8) =>
    new Decimal(n).toDecimalPlaces(digits).toString()
);
Vue.prototype.$http = http;
Vue.prototype.$EventBus = new Vue();
Vue.config.productionTip = false;

const unsub = firebase.auth().onAuthStateChanged(async user => {
    if (user) {
        const idToken = await user?.getIdToken();
        const searchRes = await axios.get(`/twofactorauth/is-enabled/${user?.uid}`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });

        if (searchRes.data?.fa2enabled && !searchRes.data?.validatedAt) {
            await firebase.auth().signOut();
        }
    }

    const app = new Vue({
        router,
        store,
        i18n,
        render: h => h(App),
    });
    app.$mount('#app');
    unsub();
});
