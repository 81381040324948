












import Vue from 'vue';
import Component from 'vue-class-component';
import ResetPasswordForm from '../components/ResetPasswordForm.vue';
import SetNewPasswordForm from '../components/SetNewPasswordForm.vue';
import EmailVerification from '@/components/EmailVerification.vue';

@Component({
    name: 'ResetPassword',
    components: {
        ResetPasswordForm,
        SetNewPasswordForm,
        EmailVerification,
    },
})
export default class ActionsPage extends Vue {
    code?: string = '';
    mode?: string = '';

    mounted() {
        this.code = (this.$route.query.oobCode as string | undefined) || '';
        this.mode = (this.$route.query.mode as string | undefined) || '';
    }
}
