






























































































































































































































































































































































































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import VeriTiseText from '@/components/VeriTiseText.vue';
import CopyTextComponent from '@/components/CopyTextComponent.vue';
import firebase from 'firebase';
import { Refs } from '@/utils/refs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-var-requires
const IBANValidator = require('iban');
// eslint-disable-next-line camelcase
import {
    CryptoToken,
    DepositRequest,
    DepositRequest_Filled,
    ReferredBonusAmount,
} from '@/sharedTypes';
import Decimals from 'decimal.js';
import { DECIMALS } from '../../constants';
import Decimal from 'decimal.js';
import axios from 'axios';
import CountDownComponent from '@/components/CountDownComponent.vue';

@Component({
    components: {
        CountDownComponent,
        CopyTextComponent,
        VeriTiseText,
    },
    watch: {
        iban(val: string) {
            this.iban = val.trim();
        },
    },
})
class CompleteTransaction extends Vue {
    // eslint-disable-next-line camelcase
    data: DepositRequest | DepositRequest_Filled | undefined | null = null;
    amount = 15000;
    cost = 0.04;
    amountToken = 0.0025;
    token = 'BTC';
    tokenText = '';
    depositAddress = '1bc2lakdjs21090ia091nu912898asd';
    txID = '';
    userID = '';
    isDisabled = true;
    isAllSold = false;
    useReferral = false;
    referredBonusAmount: ReferredBonusAmount = {
        wire: 0,
        usdt: 0,
        eth: 0,
        btc: 0,
    };
    referralToken = 'eth';
    ladder: any = null;
    showConfirmationModal = false;
    iban = '';
    ladderUnSub = (): any => null;
    unSub = (): any => null;
    unSubUserData = (): any => null;

    async sendIban() {
        this.logPurchaseGaEvent('IBANButton', 'send');
        (Refs.forUser(this.userID).deposits.single(this.txID) as any).update({
            yourIBAN: this.iban,
        });
    }

    isValidIBAN(v) {
        return IBANValidator.isValid(v);
    }

    async mounted() {
        this.txID = this.$route.params.txID;
        this.userID = firebase.auth().currentUser?.uid || '';

        this.ladderUnSub = Refs.stats('ladder').onSnapshot(next => {
            this.ladder = next.data()!;
            this.ladder = Object.keys(this.ladder).map(key => this.ladder[key]);

            this.isAllSold = !this.ladder.find(l => l.statusClass === 'available');

            this.$forceUpdate();
        });

        this.unSub = Refs.forUser(this.userID)
            .deposits.single(this.txID)
            .onSnapshot(snap => {
                // eslint-disable-next-line
                this.data = snap.data()!;

                this.tokenText = this.data?.token === 'wire' ? '€' : this.data.token;
                this.iban = this.data?.token === 'wire' ? this.data?.yourIBAN : '';
                this.useReferral = (snap.data() as DepositRequest_Filled)?.useBonus ?? false;
                this.referralToken = this.data.token;

                if (this.data.depositAddress) {
                    this.isDisabled = false;
                }

                if ((snap.data() as DepositRequest_Filled).status === 'completed') {
                    this.$router.push(`/dashboard/${this.userID}`);
                }

                this.$forceUpdate();
            });

        this.unSubUserData = Refs.forUser(this.userID).wp.data.onSnapshot(snap => {
            const data = snap.data()!;

            if (data.referredBonusAmount) {
                this.referredBonusAmount = data.referredBonusAmount;
            }

            this.$forceUpdate();
        });
    }

    destroyed() {
        this.unSub();
        this.unSubUserData();
        this.ladderUnSub();
    }

    pay() {
        this.logPurchaseGaEvent('button', 'payNow');

        const ADVCASH_EMAIL = 'cormac@veritise.com';
        const ADVCASH_API_NAME = 'ProductionSCI';

        const baseUrl = 'https://wallet.advcash.com/sci/';
        const params = new URLSearchParams({
            ac_account_email: ADVCASH_EMAIL,
            ac_sci_name: ADVCASH_API_NAME,
            ac_amount: this.paidWithValue,
            ac_currency: 'EUR',
            ac_order_id: this.txID,
            user_id: this.userID,
            // ac_success_url: axios.defaults.baseURL + '/payment/success',
            // ac_success_url_method: 'GET',
            // ac_fail_url: axios.defaults.baseURL + '/payment/fail',
            // ac_fail_url_method: 'GET',
        });

        window.open(baseUrl + '?' + params.toString(), '_blank').focus();
    }

    cancelTransaction() {
        if (this.isDisabled) {
            return;
        }

        this.logPurchaseGaEvent('button', 'cancelConfirm');

        (Refs.forUser(this.userID).deposits.single(this.txID) as any).update({
            status: 'cancelled',
        });
        this.$router.push(`/dashboard/${this.userID}`);
    }

    roundAmount(amount: number | string | undefined, token: keyof typeof DECIMALS) {
        return new Decimals(amount ?? 0)
            .toDecimalPlaces(
                DECIMALS[token],
                token === 'vts' ? Decimal.ROUND_UP : Decimal.ROUND_HALF_UP
            )
            .toString();
    }

    prepareCrossLadderInfo(data: DepositRequest | DepositRequest_Filled | undefined | null) {
        if (!data?.crossLadderInfo) {
            return [];
        }

        return data.crossLadderInfo
            .map(cross => ({
                ...cross,
                price: cross.fiatAmount ? cross.price : 0,
                amountVeriTise: cross.amountVeriTiseFromDiscount
                    ? cross.amountVeriTise - cross.amountVeriTiseFromDiscount
                    : cross.amountVeriTise,
            }))
            .filter(info => info.fiatAmount);
    }

    getTotalVeritiseAmount(data: DepositRequest | DepositRequest_Filled | undefined | null) {
        if (!data) {
            return 0;
        }

        if (data.crossLadderInfo) {
            return data.crossLadderInfo.reduce((acc, cur) => acc + Number(cur.amountVeriTise), 0);
        }

        return data.amountVeriTise;
    }

    getTotalDiscountVeritiseAmount(
        data: DepositRequest | DepositRequest_Filled | undefined | null
    ) {
        if (!data?.crossLadderInfo) {
            return 0;
        }

        return data.crossLadderInfo
            .filter(info => !info.fiatAmount || info.amountVeriTiseFromDiscount)
            .reduce(
                (acc, cur) => acc + Number(cur.amountVeriTiseFromDiscount ?? cur.amountVeriTise),
                0
            );
    }

    getTokenSymbol(token: CryptoToken | 'wire') {
        return token === 'wire' ? '€' : token;
    }

    onCheck(evt) {
        this.logPurchaseGaEvent('referralBonusesCheckbox', 'use');

        if (evt.target.checked) {
            this.useReferral = true;
            Refs.forUser(this.userID)
                .deposits.single(this.txID)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .set({ useBonus: true }, { merge: true });
        }
    }

    async confirmPurchaseByBonuses() {
        const idToken = await firebase.auth().currentUser!.getIdToken();
        await axios
            .get(`/apiComplete/complete/${this.txID}`, {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            })
            .then(r => {
                console.log(r);
                // this.$router.push(`/dashboard/${this.userID}`)
            })
            .catch(e => {
                console.log(e);
            });
    }

    get paidWithValue() {
        return this.roundAmount(this.data?.amount, this.data?.token);
    }

    private logPurchaseGaEvent(elementName: string, action: string) {
        const capitalizeFirstLetter = (value: string) => value[0].toUpperCase() + value.slice(1);

        const gaEvent =
            'Purchase' +
            this.data?.token.toUpperCase() +
            capitalizeFirstLetter(elementName) +
            capitalizeFirstLetter(action);

        this.$analytics.logEvent(gaEvent);
    }
}

export default CompleteTransaction;
