


























// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import VeriTiseText from '@/components/VeriTiseText.vue';
import firebase from 'firebase';

@Component({
    components: {
        VeriTiseText,
    },
})
class AllSold extends Vue {
    userID = '';

    async mounted() {
        this.userID = firebase.auth().currentUser?.uid || '';
    }
}

export default AllSold;
