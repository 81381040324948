





























































import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import axios from 'axios';

@Component({
    components: {},
    props: {
        isSetupFlow: Boolean,
    },
})
export default class CheckStep extends Vue {
    private error = false;
    async inputNumber(elem) {
        this.error = false;

        const inputs = document.querySelectorAll('#input-group input');
        const currentIndex = Array.from(inputs).indexOf(elem.target);

        if (elem.data === null && currentIndex === 0) {
            return;
        }

        if (elem.data === null && elem.inputType === 'deleteContentBackward') {
            this.$refs['input' + (currentIndex - 1)].focus();
            return;
        }

        if (elem.data < -1 && elem.data > 10) {
            return;
        }

        elem.target.value = elem.data;

        if (currentIndex > -1 && currentIndex < 5) {
            this.$refs['input' + (currentIndex + 1)].focus();
        }

        const inputsArr = Array.from(inputs);
        let faToken = '';

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < inputsArr.length; ++index) {
            faToken += inputsArr[index].value;

            if (faToken.length === 6) {
                await this.save(faToken);
            }
        }
    }

    async save(faToken) {
        const uid = firebase.auth().currentUser!.uid;
        const idToken = await firebase.auth().currentUser!.getIdToken();
        const method = this.isSetupFlow ? 'check-totp' : 'disable';
        const r = await axios.get(`/twofactorauth/${method}/${faToken}/${uid}`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });

        if (r.data.result) {
            this.$emit('nextStep');
        } else {
            this.error = true;
        }
    }

    async mounted() {
        this.$emit('changeLoading', true);
    }
}
