
import Vue from 'vue';
import Component from 'vue-class-component';
import axios from 'axios';

@Component({
    name: 'StatusPage',
    components: {},
})
export default class StatusPage extends Vue {
    ac_order_id?: string = '';
    user_id?: string = '';
    ac_transfer?: string = '';
    ac_start_date?: string = '';
    ac_sci_name?: string = '';
    ac_src_wallet?: string = '';
    ac_dest_wallet?: string = '';
    ac_amount?: string = '';
    ac_merchant_currency?: string = '';
    ac_hash?: string = '';

    async mounted() {
        this.ac_order_id = (this.$route.query.ac_order_id as string | undefined) || '';
        this.user_id = (this.$route.query.user_id as string | undefined) || '';
        this.ac_transfer = (this.$route.query.ac_transfer as string | undefined) || '';
        console.log(
            'ac_order_id',
            this.ac_order_id,
            'user_id',
            this.user_id,
            'ac_transfer',
            this.ac_transfer
        );
        // this.ac_start_date = (this.$route.query.ac_start_date as string | undefined) || '';
        // this.ac_sci_name = (this.$route.query.ac_sci_name as string | undefined) || '';
        // this.ac_src_wallet = (this.$route.query.ac_src_wallet as string | undefined) || '';
        // this.ac_dest_wallet = (this.$route.query.ac_dest_wallet as string | undefined) || '';
        // this.ac_amount = (this.$route.query.ac_amount as string | undefined) || '';
        // this.ac_merchant_currency = (this.$route.query.ac_merchant_currency as string | undefined) || '';
        // this.ac_hash = (this.$route.query.ac_hash as string | undefined) || '';

        // await axios.get(
        //     `/advCashHandlers/payment/status?ac_order_id=${this.ac_order_id}&user_id=${this.user_id}&ac_transfer=${this.ac_transfer}&ac_start_date=${this.ac_start_date}&ac_sci_name=${this.ac_sci_name}&ac_src_wallet=${this.ac_src_wallet}&ac_dest_wallet=${this.ac_dest_wallet}&ac_amount=${this.ac_amount}&ac_merchant_currency=${this.ac_merchant_currency}`
        // );
        await axios.get(
            `/advCashHandlers/payment/status?ac_order_id=${this.ac_order_id}&user_id=${this.user_id}&ac_transfer=${this.ac_transfer}`
        );
    }
}
