



import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';

declare var bootstrap: any;

@Component({
    props: {
        started: String,
    },
})
export default class CountDownComponent extends Vue {
    expiresAt = 0;
    timeLeft = '';

    created() {
        this.expiresAt = moment((12 * 60 * 60 + parseInt(this.started)) * 1000, 'unix');

        if (this.expiresAt.diff(moment(), 'seconds') < 0) {
            return;
        }

        const diffTime = this.expiresAt.diff(moment());
        let duration = moment.duration(diffTime, 'milliseconds', true);
        setInterval(() => {
            duration = moment.duration(duration - 1000, 'milliseconds');

            if (duration.seconds() < 0) {
                this.timeLeft = 0;
            }

            const hours = duration.hours() < 10 ? '0' + duration.hours() : duration.hours();
            const minutes = duration.minutes() < 10 ? '0' + duration.minutes() : duration.minutes();
            const seconds = duration.seconds() < 10 ? '0' + duration.seconds() : duration.seconds();

            this.timeLeft = hours + ':' + minutes + ':' + seconds;
        }, 1000);
    }
}
