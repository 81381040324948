











































































































































































































































































































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import VeriTiseText from '@/components/VeriTiseText.vue';
import PurchaseHistory from '@/components/dashboard/PurchaseHistory.vue';
import ReferralHistory from '@/components/dashboard/ReferralHistory.vue';
import CopyTextComponent from '@/components/CopyTextComponent.vue';
import { Refs } from '@/utils/refs';
import {
    CryptoToken,
    DepositRequest_Filled,
    ReferredBonusAmount,
    UserDataWriteProtected,
} from '@/sharedTypes';
import KYCBox from '@/components/dashboard/KYCBox.vue';
import firebase from 'firebase';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const IBANValidator = require('iban');

@Component({
    components: {
        KYCBox,
        CopyTextComponent,
        ReferralHistory,
        PurchaseHistory,
        VeriTiseText,
    },
    watch: {
        wireForm: {
            handler() {
                this.validateForm();
            },
            deep: true,
        },
        address() {
            this.validateForm();
        },
        token() {
            this.validateForm();
        },
    },
    computed: {
        user() {
            return firebase.auth().currentUser;
        },
    },
})
class ClaimRiseTokens extends Vue {
    privUserData: UserDataWriteProtected | null | undefined = null;
    showAlert = false;
    showError = false;
    errorMessage = 'Something went wrong';
    pending = false;
    tutorial = false;
    token: CryptoToken | 'vts' = 'vts';
    wireForm = {
        receiver_name: '',
        iban: '',
        bic: '',
    };
    country = '';
    private blackListCountries = [
        'US',
        'LK',
        'LA',
        'FJ',
        'SC',
        'ET',
        'WS',
        'IQ',
        'KP',
        'ZW',
        'AS',
        'IR',
        'KH',
        'YE',
        'PA',
        'VG',
        'BA',
        'VU',
        'PK',
        'VI',
        'BW',
        'TN',
        'UG',
        'GY',
        'BS',
        'TT',
        'OM',
        'GU',
        'AI',
        'SY',
        'MN',
        'GH',
        'AF',
    ];
    pendingReferral: ReferredBonusAmount = { btc: 0, eth: 0, usdt: 0, wire: 0 };
    formErrors = [];
    depositAddress: string | null = null;
    address = '';
    amount = 0;

    unsubs: any[] = [];

    async mounted() {
        const uid = await this.user.uid;
        this.country =
            (await Refs.forUser(uid)
                .wp.data.get()
                .then(r => r.data()?.country)) || '';
        this.unsubs.push(
            Refs.forUser(uid).wp.data.onSnapshot(next => {
                this.privUserData = next.data() || null;
                this.validateForm();
                this.$forceUpdate();
            })
        );

        this.unsubs.push(
            Refs.forUser(uid)
                .deposits.all.where('status', '==', 'pending')
                .onSnapshot(docsList => {
                    docsList.docs.forEach(doc => {
                        const docData = doc.data() as DepositRequest_Filled;
                        this.pendingReferral[docData.token] = docData.bonusAmount || 0;
                    });

                    this.$forceUpdate();
                })
        );
    }

    destroyed() {
        this.unsubs.forEach(a => a());
    }

    get withdrawInvalid() {
        return (
            this.formErrors.length ||
            (this.privUserData && !this.privUserData.kycVerified) ||
            (this.token !== 'wire' && !this.address.length) ||
            !this.affiliateAndVtsAmounts[this.token] > 0 ||
            (this.token === 'vts' && !/^[a-zA-Z0-9]{1,39}$/.test(this.address))
        );
    }

    isBlacklisted(): boolean {
        return !this.country || (this.country! && this.blackListCountries.includes(this.country!))
            ? true
            : false;
    }

    async showSuccess() {
        this.showAlert = true;
        setTimeout(() => {
            this.showAlert = false;
        }, 3000);
    }

    async showErrorAlert(message) {
        this.showError = true;
        this.errorMessage = message;
        setTimeout(() => {
            this.showError = false;
        }, 3000);
    }

    get affiliateAndVtsAmounts() {
        return {
            vts: this.privUserData?.purchasedAmount,
            btc:
                (this.privUserData?.referredBonusAmount?.btc || 0) -
                    (this.pendingReferral?.btc || 0) ?? 0,
            eth:
                (this.privUserData?.referredBonusAmount?.eth || 0) -
                    (this.pendingReferral?.eth || 0) ?? 0,
            usdt:
                (this.privUserData?.referredBonusAmount?.usdt || 0) -
                    (this.pendingReferral?.usdt || 0) ?? 0,
            wire:
                (this.privUserData?.referredBonusAmount?.wire || 0) -
                    (this.pendingReferral?.wire || 0) ?? 0,
        };
    }

    async validateForm() {
        this.formErrors = [];

        if (!this.privUserData?.kycVerified) {
            this.formErrors.push('First you need to successfully pass identity verification');
        }

        if (this.token === 'wire' && this.wireForm.iban.length) {
            const isIBANValid =
                this.wireForm.iban.length < 50 && IBANValidator.isValid(this.wireForm.iban);

            if (!isIBANValid) {
                this.formErrors.push('IBAN is not valid');
            }

            const isValid = this.wireForm.iban && this.wireForm.receiver_name && this.wireForm.bic;

            if (!isValid) {
                this.formErrors.push('Please fill in wire transfer form');
            }
        }

        const minAmounts = {
            vts: 0,
            wire: 500,
            btc: 0.015,
            eth: 0.15,
            usdt: 500,
        };

        if (
            this.token !== 'vts' &&
            this.affiliateAndVtsAmounts[this.token] < minAmounts[this.token]
        ) {
            const minAmount = minAmounts[this.token];
            const tokenName = (this.token === 'wire' ? 'EUR' : this.token).toUpperCase();
            this.formErrors.push(`
                The minimum withdrawal amount must be at least ${minAmount} ${tokenName}.
                If you do not have enough funds in this currency, you can withdraw money in VTS tokens.
            `);
        }

        if (
            this.token === 'vts' &&
            this.address.length &&
            !/^[a-zA-Z0-9]{1,39}$/.test(this.address)
        ) {
            this.formErrors.push(`VTS address not valid`);
        }
    }

    async startWithdraw() {
        this.validateForm();
        this.gaLogSendRequestEvent();

        if (this.formErrors.length) {
            return;
        }

        const uid = await this.user.uid;

        const doc = Refs.forUser(uid).withdraws.all.doc();
        await doc.set(
            {
                address: this.address,
                status: 'pending',
                amount: this.affiliateAndVtsAmounts[this.token],
                token: this.token,
                wireForm: this.wireForm,
            },
            {}
        );

        this.unsubs.push(
            Refs.forUser(uid)
                .withdraws.single(doc.id)
                .onSnapshot(next => {
                    const r = next.data() || null;

                    if (r?.status === 'cancelled') {
                        this.showErrorAlert(r?.reason);
                        this.formErrors = [];
                    } else if (r?.status === 'pending') {
                        this.showSuccess();
                        this.formErrors = [];
                    }

                    this.$forceUpdate();
                })
        );
    }

    private gaLogSendRequestEvent() {
        const gaFundsMap = {
            wire: 'EUR',
            usdt: 'USDt',
        };

        const createGaEvent = (token: string) => `WithdrawFunds${token}ButtonSendRequest`;

        const gaWithdrawFundsSendRequestEvent = gaFundsMap[this.token]
            ? createGaEvent(gaFundsMap[this.token])
            : createGaEvent(this.token.toUpperCase());

        this.$analytics.logEvent(gaWithdrawFundsSendRequestEvent);
    }
}

export default ClaimRiseTokens;
