













































































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import VeriTiseText from '@/components/VeriTiseText.vue';
import { DECIMALS } from '../../../constants';
import Decimals from 'decimal.js';
import Decimal from 'decimal.js';

declare var bootstrap: any;

@Component({
    components: {
        VeriTiseText,
    },
    props: {
        transaction: Object,
    },
})
export default class ShowTransactionModal extends Vue {
    modal: any;

    async mounted() {
        this.modal = new bootstrap.Modal(this.$refs.showModal, {
            backdrop: 'static',
            keyboard: false,
        });
    }

    async modalShow() {
        this.modal.show();
    }

    roundAmount(amount: number | string | undefined, token: keyof typeof DECIMALS) {
        return new Decimals(amount ?? 0)
            .toDecimalPlaces(
                DECIMALS[token],
                token === 'vts' ? Decimal.ROUND_UP : Decimal.ROUND_HALF_UP
            )
            .toString();
    }

    async hide() {
        this.$emit('closeModal');
        this.modal.hide();
    }
}
