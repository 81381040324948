<template>
    <span>{{ displayTimeLeft }}</span>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    name: 'Timer',
    props: {
        onEnd: Function,
        seconds: Number,
    },
    watch: {
        timeLeft: {
            handler() {
                if (this.timeLeft > 0) {
                    setTimeout(this.formatTime, 1000);
                } else {
                    this.$props.onEnd();
                }
            },
        },
    },
})
export default class Timer extends Vue {
    timeLeft = this.$props.seconds; // in sec
    displayTimeLeft = '';

    formatTime() {
        const newTime = this.timeLeft - 1;
        const mins = Math.floor(newTime / 60);
        const secs = newTime - mins * 60;

        this.timeLeft = newTime;
        this.displayTimeLeft = `0${mins}:${secs > 9 ? secs : `0${secs}`}`;
    }

    mounted() {
        this.formatTime();
    }
}
</script>
