
























import Vue from 'vue';
import Component from 'vue-class-component';
import VeriTiseText from '@/components/VeriTiseText.vue';

@Component({
    components: { VeriTiseText },
})
export default class Header extends Vue {}
