








































































import Vue from 'vue';
import Component from 'vue-class-component';
import VeriTiseText from '@/components/VeriTiseText.vue';
import firebase from 'firebase';
import { UserDataReferralPurchase } from '@/sharedTypes';
import { DECIMALS } from '../../../constants';
import Decimals from 'decimal.js';
import Decimal from 'decimal.js';

@Component({
    components: { VeriTiseText },
    props: {
        referrals: Array,
    },
})
export default class ReferralHistory extends Vue {
    private referrals!: UserDataReferralPurchase[];
    userID = firebase.auth().currentUser?.uid;
    clipboardText = `${window.location.origin}/ref/${this.userID}`;

    roundAmount(amount: number | string | undefined, token: keyof typeof DECIMALS) {
        return new Decimals(amount ?? 0)
            .toDecimalPlaces(
                DECIMALS[token],
                token === 'vts' ? Decimal.ROUND_UP : Decimal.ROUND_HALF_UP
            )
            .toString();
    }
}
