










import Vue from 'vue';
import Component from 'vue-class-component';
import ResetPasswordForm from '../components/ResetPasswordForm.vue';

@Component({
    name: 'ResetPassword',
    components: {
        ResetPasswordForm,
    },
})
export default class ResetPassword extends Vue {}
