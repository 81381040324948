/* eslint-disable */
import firebase from 'firebase';
import { WithdrawRequest, WithdrawRequest_Completed, WithdrawRequest_Filled } from 'sharedTypes';
import {
    ChartInfoData,
    CurLadder,
    DepositRequest,
    DepositRequest_Completed,
    DepositRequest_Filled,
    DepositStatus,
    IDiscountInfo,
    KYC,
    UserData,
    UserDataReferralPurchase,
    UserDataWriteProtected,
    JustStartedKYC, ILadder,
} from '../sharedTypes';

export class Refs {
    static forUser(userID: string) {
        return {
            main: this.user(userID),
            wp: {
                data: this.userWriteProtectedData(userID),
                referralsPurchases: this.userWriteProtectedReferrals(userID),
                kyc: {
                    main: this.userWriteProtectedKYC(userID),
                    requests: this.userWriteProtectedKYC(userID).collection(
                        'requests'
                    ) as firebase.firestore.CollectionReference<JustStartedKYC | KYC>,
                    request: (doc: string) =>
                        (
                            this.userWriteProtectedKYC(userID).collection(
                                'requests'
                            ) as firebase.firestore.CollectionReference<KYC>
                        ).doc(doc),
                },
            },
            charts: {
                buyer: this.buyersChart(userID),
                referral: this.referralsChart(userID),
            },
            claim: {},
            deposits: {
                all: this.userDeposits(userID),
                single(depositID: string) {
                    return Refs.userDeposits(userID).doc(depositID);
                },
            },
            withdraws: {
                all: this.userWithdrawRequest(userID),
                 single(depositID: string) {
                    return Refs.userWithdrawRequest(userID).doc(depositID);
                },
            },
        };
    }

    static user(userID: string): firebase.firestore.DocumentReference<UserData> {
        return firebase.firestore().doc(`users/${userID}`) as any;
    }

    static userWriteProtectedData(
        userID: string
    ): firebase.firestore.DocumentReference<UserDataWriteProtected> {
        return firebase.firestore().doc(`users/${userID}/writeProtected/data`);
    }
    static userWriteProtectedKYC(userID: string): firebase.firestore.DocumentReference<KYC> {
        return firebase.firestore().doc(`users/${userID}/writeProtected/kyc`) as any;
    }

    static userWriteProtectedReferrals(
        userID: string
    ): firebase.firestore.CollectionReference<UserDataReferralPurchase> {
        return firebase.firestore().collection(`users/${userID}/referralPurchases`) as any;
    }

    static userDeposits(
        userID: string
    ):
        | firebase.firestore.CollectionReference<DepositRequest>
        | firebase.firestore.CollectionReference<DepositRequest_Completed>
        | firebase.firestore.CollectionReference<DepositRequest_Filled>;
    static userDeposits(
        userID: string,
        depositID: string
    ):
        | firebase.firestore.DocumentReference<DepositRequest>
        | firebase.firestore.DocumentReference<DepositRequest_Completed>
        | firebase.firestore.DocumentReference<DepositRequest_Filled>;
    static userDeposits(userID: string, depositID?: string) {
        if (depositID) {
            this.userDeposits(userID).doc(depositID);
        }
        return this.user(userID).collection('deposit') as any;
    }
    static userWithdrawRequest(
        userID: string
    ):
        | firebase.firestore.CollectionReference<WithdrawRequest>
        | firebase.firestore.CollectionReference<WithdrawRequest_Completed>
        | firebase.firestore.CollectionReference<WithdrawRequest_Filled>;
    static userWithdrawRequest(
        userID: string,
        depositID: string
    ):
        | firebase.firestore.DocumentReference<WithdrawRequest>
        | firebase.firestore.DocumentReference<WithdrawRequest_Completed>
        | firebase.firestore.DocumentReference<WithdrawRequest_Filled>;
    static userWithdrawRequest(userID: string, depositID?: string) {
        if (depositID) {
            return this.userWithdrawRequest(userID).doc(depositID);
        }

        return this.user(userID).collection('withdrawRequest') as any;
    }

    static claimed(
        userID: string,
        address: string,
        amount: number,
    ): firebase.firestore.DocumentReference<{ status: DepositStatus; reason?: string, amount: number }>;
    static claimed(
        userID: string
    ): firebase.firestore.CollectionReference<{ status: DepositStatus; reason?: string, amount: number }>;
    static claimed(userID: string, address?: string) {
        if (address) {
            return this.claimed(userID).doc(address);
        }
        return firebase.firestore().collection(`users/${userID}/claimAddresses`) as any;
    }

    static stats(docID: 'infos'): firebase.firestore.DocumentReference<StatsInfos>;
    static stats(docID: 'ladder'): firebase.firestore.DocumentReference<{
        [index: string]: ILadder;
    }>;
    static stats(
        docID: 'prices'
    ): firebase.firestore.DocumentReference<{ btc: string; eth: string; usdt: string }>;
    static stats(): firebase.firestore.CollectionReference<any>;
    static stats(docID?: 'infos' | 'prices' | 'ladder') {
        if (docID) {
            return this.stats().doc(docID);
        }
        return firebase.firestore().collection('stats');
    }

    static discounts(docID: 'discounts'): firebase.firestore.DocumentReference<IDiscountInfo>;
    static discounts<T>(): firebase.firestore.CollectionReference<T>;
    static discounts(docID?: 'discounts') {
        if (docID) {
            return this.discounts().doc(docID);
        }
        return firebase.firestore().collection('info');
    }

    static info(docID: 'curLadderInfo'): firebase.firestore.DocumentReference<CurLadder>;
    static info<T>(): firebase.firestore.CollectionReference<T>;
    static info(docID?: 'curLadderInfo') {
        if (docID) {
            return this.info().doc(docID);
        }
        return firebase.firestore().collection('info');
    }

    static buyersChart(userID: string): firebase.firestore.DocumentReference<ChartInfoData>;
    static buyersChart(): firebase.firestore.CollectionReference<ChartInfoData>;
    static buyersChart(userID?: string) {
        if (userID) {
            return this.buyersChart().doc(userID);
        }
        return firebase.firestore().collection(`buyersChart`);
    }

    static referralsChart(userID: string): firebase.firestore.DocumentReference<ChartInfoData>;
    static referralsChart(): firebase.firestore.CollectionReference<ChartInfoData>;
    static referralsChart(userID?: string) {
        if (userID) {
            return this.referralsChart().doc(userID);
        }
        return firebase.firestore().collection(`referralsChart`);
    }

    static pendingDeposits(depositID: string): firebase.firestore.DocumentReference<any>;
    static pendingDeposits(): firebase.firestore.CollectionReference<any>;
    static pendingDeposits(depositID?: string) {
        if (depositID) {
            return this.pendingDeposits().doc(depositID);
        }
        return firebase.firestore().collection('_pendingDeposits');
    }

    static pendingClaims(depositID: string): firebase.firestore.DocumentReference<ClaimInfo>;
    static pendingClaims(): firebase.firestore.CollectionReference<ClaimInfo>;
    static pendingClaims(depositID?: string) {
        if (depositID) {
            return this.pendingClaims().doc(depositID);
        }
        return firebase.firestore().collection('_pendingClaims');
    }

    static claimedAddress(
        address: string
    ): firebase.firestore.DocumentReference<{ userID: string }> {
        return firebase.firestore().doc(`_claimedAddresses/${address}`) as any;
    }
}

export type StatsInfos = {
    totalReferrals: number;
    totalTransactions: number;
    verifiedUsers: number;
    totalUsers: number;
};
export type ClaimInfo = {
    status: DepositStatus;
    expiresAt: number;
    address: string;
    checkAddress: string;
    userID: string;
};
