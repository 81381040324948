








import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';

@Component({})
export default class TotalSalesSupply extends Vue {
    private highChartsOptions: any = null;

    async mounted() {
        const snap = await firebase.firestore().collection('stats').doc('sales').get();
        const { sold, available } = snap.data() as any;
        this.highChartsOptions = {
            chart: { type: 'pie' },
            credits: {
                enabled: false,
            },
            title: {
                text: '100 Million VeriTise',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                        distance: -50,
                        filter: {
                            property: 'percentage',
                            operator: '>',
                            value: 4,
                        },
                    },
                    showInLegend: true,
                },
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
            },
            accessibility: {
                point: {
                    valueSuffix: '%',
                },
            },

            series: [
                {
                    data: [
                        {
                            name: 'Sold',
                            y: sold,
                            color: '#0072Fd',
                        },
                        {
                            name: 'Available',
                            y: available,
                            color: '#1e508b',
                        },
                    ], // sample data
                },
            ],
        };
    }
}
