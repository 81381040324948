






// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import TransactionsTable from '@/components/transactions/TransactionsTable.vue';
// eslint-disable-next-line camelcase

@Component({
    components: {
        TransactionsTable,
    },
})
class Transactions extends Vue {}

export default Transactions;
