

















































import Vue from 'vue';
import Component from 'vue-class-component';
import VeriTiseText from '@/components/VeriTiseText.vue';
// eslint-disable-next-line camelcase
import { WithdrawRequest_Filled } from '../../../sharedTypes';
import Decimals from 'decimal.js';
import { DECIMALS } from '../../../constants';
import Decimal from 'decimal.js';

@Component({
    components: { VeriTiseText },
    props: {
        withdraws: Array,
    },
})
export default class WithdrawHistory extends Vue {
    // eslint-disable-next-line camelcase
    private withdraws!: (WithdrawRequest_Filled & { dbID: string })[];

    roundAmount(amount: number | string | undefined, token: keyof typeof DECIMALS) {
        return new Decimals(amount ?? 0)
            .toDecimalPlaces(
                DECIMALS[token],
                token === 'vts' ? Decimal.ROUND_UP : Decimal.ROUND_HALF_UP
            )
            .toString();
    }

    get sortedWithdrawsByDesc() {
        return this.withdraws.sort((a, b) => {
            if (isNaN(a.startedAt) && b.startedAt) {
                return 1;
            }

            return a.startedAt < b.startedAt ? 1 : -1;
        });
    }
}
