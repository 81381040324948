













import Vue from 'vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import Login from './components/Login.vue';
import Component from 'vue-class-component';
import CountryModal from '@/components/CountryModal.vue';
import 'vue-select/dist/vue-select.css';
import firebase from 'firebase';

declare var bootstrap;

@Component({
    components: { CountryModal, Header, Footer, Login },
})
export default class App extends Vue {
    private isLoggedin = false;

    async mounted() {
        firebase.auth().onAuthStateChanged(async user => {
            if (
                !user?.emailVerified &&
                !user?.phoneNumber &&
                !user?.providerData?.some(item => item?.providerId === 'facebook.com')
            ) {
                this.isLoggedin = false;
                this.$store.commit('LOGOUT');
                firebase.auth().signOut();
            } else {
                this.isLoggedin = true;
                this.$store.commit('LOGIN_SUCCESS');
            }
        });
    }
}
