





































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import { IBlogArticle } from '../../../sharedTypes/blog';
// eslint-disable-next-line camelcase

@Component({
    components: {},
})
export default class News extends Vue {
    private loading = true;
    private articles: IBlogArticle[] = [];

    async mounted() {
        await firebase
            .firestore()
            .collection('blog')
            .onSnapshot(next => {
                this.articles = next.docs.map(a => a.data() as IBlogArticle);
            });

        this.loading = false;
    }

    sliceText = (text: string, length: number) => {
        if (text.length < length) {
            return text;
        }

        return `${text.slice(0, length)}...`;
    };
}
