






























// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import axios from 'axios';
// eslint-disable-next-line camelcase

@Component({
    components: {},
})
export default class Dashboard extends Vue {
    private loading = true;
    private isEnabled = false;

    async mounted() {
        const uid = firebase.auth().currentUser!.uid;
        const idToken = await firebase.auth().currentUser!.getIdToken();
        const searchRes = await axios.get(`/twofactorauth/is-enabled/${uid}`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });

        const fa2enabled = searchRes.data?.fa2enabled;

        if (fa2enabled) {
            this.isEnabled = true;
        }

        this.loading = false;
    }
}
