










































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import VeriTiseText from '@/components/VeriTiseText.vue';
import { Refs } from '@/utils/refs';
import { CurLadder } from '@/sharedTypes/curLadder';
import { IDiscountInfo } from '@/sharedTypes/DiscountInfo';
import firebase from 'firebase';
import moment from 'moment';
import { DECIMALS } from '../../../constants';
import Decimals from 'decimal.js';
import Decimal from 'decimal.js';
import { CryptoToken } from '../../../sharedTypes';
import {
    getCrossLadderVeriTiesPrices,
    getTotalCrossLadderInfo,
    getUpdatedLadders,
} from '@/utils/helpers';

@Component({
    components: { VeriTiseText },
    watch: {
        token() {
            this.calculatePrices(this.amountToBuy);
        },
        amountToBuy(newValue, oldValue) {
            if (/^\d+\.?\d{0,2}$/g.test(newValue) || newValue === '') {
                this.amountToBuy = newValue;
                this.calculatePrices(newValue);
            } else {
                this.amountToBuy = oldValue;
            }
        },
    },
    computed: {
        user() {
            return firebase.auth().currentUser;
        },
    },
    // props: {
    //     country: String,
    // },
})
export default class Header extends Vue {
    private amountToBuy = '';
    private ladderPrices: {
        prices: Record<string, unknown>[];
        totalDollarAmount?: number;
        totalTokenAmount?: number;
        totalVeritiseAmount?: number;
    } = { prices: [] };
    private prices!: { btc: string; eth: string; usdt: string; wire: string; card: string };
    private token: CryptoToken | 'wire' = 'btc';
    private wireTextHovered = false;
    private curLadderInfo?: CurLadder;
    private discountInfo?: IDiscountInfo;
    country = '';
    private blackListCountries = [
        'US',
        'LK',
        'LA',
        'FJ',
        'SC',
        'ET',
        'WS',
        'IQ',
        'KP',
        'ZW',
        'AS',
        'IR',
        'KH',
        'YE',
        'PA',
        'VG',
        'BA',
        'VU',
        'PK',
        'VI',
        'BW',
        'TN',
        'UG',
        'GY',
        'BS',
        'TT',
        'OM',
        'GU',
        'AI',
        'SY',
        'MN',
        'GH',
        'AF',
    ];
    private identificationChecked = false;
    private availableLadder: any = null;
    private isAllSold: any = false;
    private unsub = (): any => null;
    private unsubLadder = (): any => null;
    private unsubCurLadder = (): any => null;
    private unsubDiscounts = (): any => null;
    async mounted() {
        const p = await Refs.stats('prices').get();
        const uid = await this.user.uid;
        this.country =
            (await Refs.forUser(uid)
                .wp.data.get()
                .then(r => r.data()?.country)) || '';
        this.unsub = Refs.stats('prices').onSnapshot(next => {
            this.prices = { ...next.data()!, wire: '1', card: '1' };
            this.$forceUpdate();
        });
        this.prices = { ...p.data()!, wire: '1', card: '1' };

        this.unsubCurLadder = Refs.info('curLadderInfo').onSnapshot(next => {
            this.curLadderInfo = next.data()!;

            this.amountToBuy = new Decimals(this.minPurchaseableTokens / this.curLadderInfo.price)
                .toDecimalPlaces(DECIMALS.vts, Decimal.ROUND_UP)
                .toString();

            this.$forceUpdate();
        });

        this.unsubLadder = Refs.stats('ladder').onSnapshot(next => {
            this.availableLadder = next.data()!;
            this.calculatePrices(this.amountToBuy);

            const ladder = Object.keys(this.availableLadder).map(key => this.availableLadder[key]);
            this.isAllSold = !ladder.find(l => l.statusClass === 'available');

            this.$forceUpdate();
        });

        this.unsubDiscounts = Refs.discounts('discounts').onSnapshot(next => {
            this.discountInfo = next.data()!;
            this.$forceUpdate();
        });
    }

    async buy() {
        this.$analytics.logEvent('DashboardPurchaseTSPButtonBuy');

        if (this.isAllSold) {
            await this.$router.push({ path: `/sold` });
            return;
        }

        if (this.isBlacklisted()) {
            return;
        }

        const depositReq = {
            amountVeriTise: +this.amountToBuy,
            startedAt: moment.utc().unix(),
            status: 'pending',
            token: this.token,
        };

        const doc = Refs.forUser(firebase.auth().currentUser!.uid!).deposits.all.doc();
        await doc.set(depositReq);

        this.$router.push({ path: `/completeTransaction/${doc.id}` });
    }

    destroyed() {
        this.unsub();
        this.unsubDiscounts();
        this.unsubCurLadder();
        this.unsubLadder();
    }

    async calculatePrices(value) {
        const amountVeritise = value || 0;

        if (amountVeritise && this.availableLadder && this.curLadderInfo) {
            let crossLadderVeriTiesPrices = getCrossLadderVeriTiesPrices(
                amountVeritise,
                this.availableLadder,
                this.curLadderInfo.index
            );

            const fiat = crossLadderVeriTiesPrices.reduce(
                (acc, cur) => acc.add(cur.fiatAmount),
                new Decimal(0)
            );
            const discount = this.getDiscountByDollarAmount(fiat.toNumber());

            const laddersData = getUpdatedLadders(this.availableLadder, crossLadderVeriTiesPrices);
            const amountVeriTiseWithDiscount = new Decimal(amountVeritise).dividedBy(1 - discount);

            if (discount) {
                const amountVeriTiseAddedByDiscount = amountVeriTiseWithDiscount
                    .sub(amountVeritise)
                    .toNumber();

                const additionalCrossLadderVeriTiesPrices = getCrossLadderVeriTiesPrices(
                    amountVeriTiseAddedByDiscount,
                    laddersData,
                    crossLadderVeriTiesPrices[crossLadderVeriTiesPrices.length - 1].ladderIndex,
                    true
                );
                crossLadderVeriTiesPrices = getTotalCrossLadderInfo(
                    additionalCrossLadderVeriTiesPrices,
                    crossLadderVeriTiesPrices.map(cross => ({
                        ...cross,
                        amountVeriTise: cross.amountVeriTise
                            .toDecimalPlaces(DECIMALS.vts)
                            .toNumber(),
                        fiatAmount: cross.fiatAmount.toDecimalPlaces(DECIMALS.fiat).toNumber(),
                        amountVeriTiseFromDiscount: cross.amountVeriTiseFromDiscount
                            ?.toDecimalPlaces(DECIMALS.vts)
                            ?.toNumber(),
                    }))
                );
            }

            this.ladderPrices = {
                totalDollarAmount: crossLadderVeriTiesPrices
                    .reduce((acc, cur) => acc.add(cur.fiatAmount), new Decimal(0))
                    .toDecimalPlaces(DECIMALS.fiat)
                    .toNumber(),
                totalTokenAmount: crossLadderVeriTiesPrices
                    .reduce(
                        (acc, cur) =>
                            acc.add(
                                cur.fiatAmount.dividedBy(new Decimal(this.prices[this.token] || 0))
                            ),
                        new Decimal(0)
                    )
                    .toDecimalPlaces(DECIMALS[this.token])
                    .toNumber(),
                totalVeritiseAmount: crossLadderVeriTiesPrices
                    .reduce((acc, cur) => acc.add(cur.amountVeriTise), new Decimal(0))
                    .toDecimalPlaces(DECIMALS.vts)
                    .toNumber(),
                prices: crossLadderVeriTiesPrices.map(cross => ({
                    vtsPriceString: new Decimals(cross.price)
                        .mul(1 - discount)
                        .toDecimalPlaces(DECIMALS.vtsPrice, Decimal.ROUND_UP)
                        .toString(),
                    discount,
                    dollarAmount: cross.fiatAmount.toNumber(),
                    tokenAmount: cross.fiatAmount
                        .dividedBy(new Decimal(this.prices[this.token]))
                        .toDecimalPlaces(DECIMALS[this.token])
                        .toNumber(),
                    amountToBuyWithDiscount: cross.amountVeriTise
                        .toDecimalPlaces(DECIMALS.vts, Decimal.ROUND_UP)
                        .toNumber(),
                })),
            };
        }
    }

    dollarAmount(price, amount): number {
        return price * +amount;
    }

    tokenAmount(dollarAmount): string {
        return new Decimals(dollarAmount)
            .dividedBy(this.prices[this.token])
            .toDecimalPlaces(DECIMALS[this.token])
            .toString();
    }

    getDiscountByDollarAmount(dollarAmount): number {
        if (!this.discountInfo) {
            return 0;
        }

        const discounts = Object.keys(this.discountInfo).map(k => this.discountInfo[k]);
        const discount = discounts.find(
            d => d.fromAmount <= dollarAmount && (d.toAmount > dollarAmount || !d.toAmount)
        );
        return discount?.discount || 0;
    }

    isBlacklisted(): boolean {
        return !this.country || (this.country! && this.blackListCountries.includes(this.country!))
            ? true
            : false;
    }

    isValid(): boolean {
        return this.isValidAmount() && this.identificationChecked && !this.isBlacklisted();
    }

    isValidMaxAmount(): boolean {
        if (this.ladderPrices.totalDollarAmount > 100000) {
            return false;
        }

        return true;
    }

    isValidAmount(): boolean {
        return (
            /^\d+\.?\d{0,2}$/g.test(this.amountToBuy) &&
            !!this.discountInfo &&
            !!this.prices &&
            !!this.availableLadder &&
            !!this.curLadderInfo &&
            (+this.amountToBuy >=
                +(this.minPurchaseableTokens / this.curLadderInfo.price).toFixed(2) ||
                this.isAllSold)
        );
    }

    get minPurchaseableTokens() {
        if (window.location.host !== 'tokensale.veritise.com') {
            return 0;
        }

        return this.token === 'wire' || this.token === 'card' ? 100 : 500;
    }
}
