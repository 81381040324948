



















































































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import PricingLadder from '../components/home/PricingLadder.vue';
import Hero from '../components/home/Hero.vue';
import Top10 from '../components/home/Top10.vue';
import StatsAndInfos from '@/components/home/StatsAndInfos.vue';
import TotalSalesSupply from '@/components/home/TotalSalesSupply.vue';
import LoginComponent from '@/components/Login.vue';
import firebase from 'firebase';
import Purchase from '@/components/dashboard/Purchase.vue';
import ClaimBox from '@/components/dashboard/ClaimBox.vue';
import GenericInfoBox from '@/components/dashboard/GenericInfoBox.vue';
import VeriTiseText from '@/components/VeriTiseText.vue';
import PurchaseHistory from '@/components/dashboard/PurchaseHistory.vue';
import WithdrawHistory from '@/components/dashboard/WithdrawHistory.vue';
import ReferralHistory from '@/components/dashboard/ReferralHistory.vue';
import { Refs } from '@/utils/refs';
import {
    UserDataReferralPurchase,
    UserDataWriteProtected,
    UserData,
    WithdrawRequest_Filled,
} from '../../sharedTypes';
import KYCBox from '@/components/dashboard/KYCBox.vue';
import { CryptoToken, DepositRequest_Filled, ReferredBonusAmount } from '@/sharedTypes';
import RanksInfoBox from '@/components/dashboard/RanksInfoBox.vue';

@Component({
    components: {
        RanksInfoBox,
        KYCBox,
        ReferralHistory,
        PurchaseHistory,
        WithdrawHistory,
        VeriTiseText,
        GenericInfoBox,
        ClaimBox,
        Purchase,
        TotalSalesSupply,
        StatsAndInfos,
        Top10,
        PricingLadder,
        Hero,
        LoginComponent,
    },
    watch: {
        aliasEdit() {
            if (this.aliasEdit.length > 255) {
                this.errorMessage = this.$t('dashboard.name_errors.max_length');
            } else if (this.aliasEdit.length < 4) {
                this.errorMessage = this.$t('dashboard.name_errors.min_length');
            } else if (/^[$&+,:;=?@#|'<>.^*()%!-]/.test(this.aliasEdit)) {
                this.errorMessage = this.$t('dashboard.name_errors.special_chr');
            } else if (this.aliasEdit.length === 0) {
                this.errorMessage = this.$t('dashboard.name_errors.empty');
            } else {
                this.errorMessage = '';
            }
        },
    },
    computed: {
        user() {
            return firebase.auth().currentUser;
        },
    },
})
class Dashboard extends Vue {
    isAdminViewing = false;
    userID = '';
    editingAlias = false;
    alias = '...';
    aliasEdit = '';
    errorMessage = '';
    buyerRankTitle = this.$t('dashboard.best_buyer_rank');
    userInfos: UserData | null = null;
    privUserData: UserDataWriteProtected | null | undefined = null;
    referrals: UserDataReferralPurchase[] = [];
    pendigReferral: ReferredBonusAmount = { btc: 0, eth: 0, usdt: 0, wire: 0 };
    referralsCount = 0;
    private user;
    // eslint-disable-next-line camelcase
    transactions: { dbID: string; token: CryptoToken; amountVeriTise: number }[] = [];
    withdraws: (WithdrawRequest_Filled & { dbID: string })[] = [];

    unsubs: any[] = [];

    async mounted() {
        this.alias = this.aliasEdit = firebase.auth().currentUser?.displayName || '...';
        this.isAdminViewing = this.alias === 'TSPAdminView';

        if (this.user.uid) {
            this.unsubs.push(
                Refs.forUser(this.user.uid).deposits.all.onSnapshot(next => {
                    this.transactions = next.docs.map(s => ({ ...s.data(), dbID: s.id })) || [];
                    this.$forceUpdate();
                })
            );
            this.unsubs.push(
                Refs.userWithdrawRequest(this.user.uid).onSnapshot(next => {
                    this.withdraws = next.docs.map(w => ({
                        ...w.data(),
                        dbID: w.id,
                    }));
                    this.$forceUpdate();
                })
            );
            this.unsubs.push(
                Refs.forUser(this.user.uid).wp.referralsPurchases.onSnapshot(next => {
                    this.referrals = next.docs.map(s => s.data());
                    this.referralsCount = this.referrals.reduce(
                        (acc, cur) => acc + Number(cur.bonus),
                        0
                    );
                    this.$forceUpdate();
                })
            );
            this.unsubs.push(
                Refs.forUser(this.user.uid).wp.data.onSnapshot(next => {
                    this.privUserData = next.data() || null;
                    this.$forceUpdate();
                })
            );

            this.unsubs.push(
                Refs.forUser(this.user.uid)
                    .deposits.all.where('status', '==', 'pending')
                    .onSnapshot(docsList => {
                        docsList.docs.forEach(doc => {
                            const docData = doc.data() as DepositRequest_Filled;
                            this.pendigReferral[docData.token] = docData.bonusAmount || 0;
                        });

                        this.$forceUpdate();
                    })
            );
        }
    }

    editName() {
        this.$analytics.logEvent('DashboardNameButtonEdit');
        this.editingAlias = true;
        this.aliasEdit = this.user.displayName || '';
    }

    destroyed() {
        this.unsubs.forEach(a => a());
    }

    keyUp(event) {
        if (event.key === 'Enter') {
            this.saveAlias();
        }
    }

    saveAlias() {
        this.$analytics.logEvent('DashboardNameButtonSave');

        if (/^[a-zA-Z ]{4,255}$/.test(this.aliasEdit)) {
            firebase.auth().currentUser?.updateProfile({
                displayName: this.aliasEdit,
            });
            this.editingAlias = false;
            this.alias = this.aliasEdit;
            this.isAdminViewing = this.alias === this.aliasEdit;
        }

        if (this.aliasEdit.length === 0) {
            this.editingAlias = false;
            this.aliasEdit = firebase.auth().currentUser?.displayName || '...';
        }
    }

    get affiliateInfoBoxAmounts() {
        return {
            btc:
                (this.privUserData?.referredBonusAmount?.btc || 0) -
                    (this.pendigReferral?.btc || 0) ?? 0,
            eth:
                (this.privUserData?.referredBonusAmount?.eth || 0) -
                    (this.pendigReferral?.eth || 0) ?? 0,
            usdt:
                (this.privUserData?.referredBonusAmount?.usdt || 0) -
                    (this.pendigReferral?.usdt || 0) ?? 0,
            eur:
                (this.privUserData?.referredBonusAmount?.wire || 0) -
                    (this.pendigReferral?.wire || 0) ?? 0,
        };
    }

    get referrerInfoBoxAmounts() {
        return {
            btc: this.privUserData?.referrerRank?.btc || 0,
            eth: this.privUserData?.referrerRank?.eth || 0,
            usdt: this.privUserData?.referrerRank?.usdt || 0,
            eur: this.privUserData?.referrerRank?.wire || 0,
        };
    }

    get isNeedEmailVerification() {
        return Boolean(
            !this.user.emailVerified &&
                !this.user.phoneNumber &&
                !this.user?.providerData?.some(item => item?.providerId === 'facebook.com')
        );
    }
}

export default Dashboard;
