





























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import VeriTiseText from '@/components/VeriTiseText.vue';
// eslint-disable-next-line camelcase
import { DepositRequest, DepositRequest_Filled } from '../../../sharedTypes';
import Decimals from 'decimal.js';
import { DECIMALS } from '../../../constants';
import Decimal from 'decimal.js';

@Component({
    components: { VeriTiseText },
    props: {
        transactions: Array,
    },
})
export default class PurchaseHistory extends Vue {
    // eslint-disable-next-line camelcase
    private transactions!: (DepositRequest_Filled & { dbID: string })[];
    explorerLink(tx: { token: string; id: string }) {
        return this.$options.filters!.explorerLink(tx.id, tx.token.toUpperCase());
    }

    roundAmount(amount: number | string | undefined, token: keyof typeof DECIMALS) {
        return new Decimals(amount ?? 0)
            .toDecimalPlaces(
                DECIMALS[token],
                token === 'vts' ? Decimal.ROUND_UP : Decimal.ROUND_HALF_UP
            )
            .toString();
    }

    prepareCrossLadderInfo(data: DepositRequest | DepositRequest_Filled | undefined | null) {
        if (!data?.crossLadderInfo) {
            return [];
        }

        return data.crossLadderInfo.map(cross => ({
            ...cross,
            price:
                Math.round(
                    cross.price * (1 - ('discount' in data ? data?.discount ?? 0 : 0)) * 100
                ) / 100,
        }));
    }

    isOverpaid(tx) {
        return parseFloat(tx.receivedTokenAmount) > parseFloat(tx.amount);
    }

    get sortedTransactionsByDesc() {
        return this.transactions.sort((a, b) => {
            if (isNaN(a.startedAt) && b.startedAt) {
                return 1;
            }

            return a.startedAt < b.startedAt ? 1 : -1;
        });
    }
}
