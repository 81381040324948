




























































import Vue from 'vue';
import Component from 'vue-class-component';
import InputPhoneNumber from 'cube-vue-phone-number-input';
import 'cube-vue-phone-number-input/src/scss/cube-phone-number-input-inline.scss';
import firebase from 'firebase';

@Component({
    name: 'PhoneLogin',
    components: {
        InputPhoneNumber,
    },
    props: {
        isPhoneValid: Boolean,
        captchaVerified: Boolean,
        showOtp: Boolean,
        onSuccess: Function,
        hasErrCountryOrTerms: Boolean,
    },
    watch: {
        otpCode(v) {
            if (this.showOtp && v.length !== 6) {
                this.$emit('setOtpValid', false);
            } else {
                this.$emit('setOtpValid', true);
                this.otpError = '';
            }
        },
    },
})
export default class PhoneLogin extends Vue {
    country = '';
    phoneNumber = '';
    otpCode = '';
    phoneError = '';
    otpError = '';
    otpValidated = false;
    success = false;
    applicationVerifier: any = null;
    verificationId = '';

    async inputNumber(elem) {
        this.otpValidated = false;

        const inputs = document.querySelectorAll('#input-group input');
        const currentIndex = Array.from(inputs).indexOf(elem.target);

        if (elem.data === null && currentIndex === 0) {
            return;
        }

        if (elem.data === null && elem.inputType === 'deleteContentBackward') {
            this.$refs['input' + (currentIndex - 1)].focus();
            return;
        }

        if (elem.data < -1 && elem.data > 10) {
            return;
        }

        elem.target.value = elem.data;

        if (currentIndex > -1 && currentIndex < 5) {
            this.$refs['input' + (currentIndex + 1)].focus();
        }

        const inputsArr = Array.from(inputs);
        let otpCode = '';

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < inputsArr.length; ++index) {
            otpCode += inputsArr[index].value;
            this.otpValidated = otpCode.length === 6;
            this.otpCode = otpCode;
        }
    }

    async phoneChange(data) {
        if (data === null) {
            this.$emit('setIsPhoneValid', false);
            this.phoneError = 'Please fill in your phone number.';
        } else {
            this.$emit('setIsPhoneValid', data.valid || data.possible);
            this.phoneNumber = data.number;

            if (data.valid || data.possible) {
                this.phoneError = '';
            } else {
                this.phoneError = 'Please enter a valid phone number.';
            }
        }
    }

    async verifyPhone() {
        this.$emit('setPhoneLoading', true);

        if (!this.captchaVerified) {
            this.applicationVerifier = await new firebase.auth.RecaptchaVerifier(
                'recaptcha-container',
                {
                    size: 'invisible',
                    callback: response => {
                        console.log(response);
                        this.$emit('setCaptchaVerified', true);
                        this.$emit('setOtpValid', false);
                        this.$emit('setPhoneLoading', true);
                    },
                }
            );
        }

        const provider = await new firebase.auth.PhoneAuthProvider();
        provider
            .verifyPhoneNumber(this.phoneNumber, this.applicationVerifier)
            .then(verificationId => {
                this.verificationId = verificationId;
                this.$emit('setShowOtp', true);
                this.$emit('setOtpValid', false);
            })
            .catch(e => {
                console.log(e);

                const err = e.message.toLowerCase().replace('_', ' ');
                this.phoneError = err.charAt(0).toUpperCase() + err.slice(1);
                this.$emit('setOtpValid', false);
                this.$emit('setShowOtp', false);
            })
            .finally(() => this.$emit('setPhoneLoading', false));
    }

    async verifyOtp() {
        this.$emit('setPhoneLoading', true);
        this.otpError = '';
        console.log('OTP country', this.country);

        const cd = firebase.auth.PhoneAuthProvider.credential(this.verificationId, this.otpCode);
        firebase
            .auth()
            .signInWithCredential(cd)
            .then(r => {
                this.$props.onSuccess(r);
                this.$emit('setOtpValid', true);
            })
            .catch(e => {
                console.log(e.message);
                this.$emit('setPhoneLoading', false);
                this.$emit('setOtpValid', false);
                this.otpError = 'Please enter a valid confirmation code.';
            });
    }

    async created() {
        if (!this.phoneNumber) {
            this.phoneError = 'Please fill in your phone number.';
        }
    }
}
