export const DECIMALS = {
    btc: 8,
    card: 2,
    eth: 8,
    fiat: 2,
    percent: 2,
    usdt: 6,
    vts: 2,
    vtsPrice: 4,
    wire: 2,
} as const;
