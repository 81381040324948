var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-6 col-s-12 text-center"},[(_vm.success)?_c('h1',[_vm._v(_vm._s(_vm.$t('resetPass.success')))]):_c('h1',[_vm._v(_vm._s(_vm.$t('resetPass.title')))]),(_vm.submitError)?_c('div',{staticClass:"alert alert-danger mt-3"},[_vm._v(_vm._s(_vm.submitError))]):_vm._e(),(_vm.success)?_c('div',{staticClass:"mt-3"},[_c('p',[_vm._v(_vm._s(_vm.$t('resetPass.has_been_reset')))]),_c('router-link',{attrs:{"to":"/"}},[_c('button',{staticClass:"btn btn-primary btn-lg w-100 rounded-pill mt-5",attrs:{"type":"submit"},on:{"click":function($event){return _vm.$analytics.logEvent('PasswordResetButtonBackToLogin')}}},[_vm._v(" "+_vm._s(_vm.$t('resetPass.back_to_login'))+" ")])])],1):_vm._e(),(!_vm.success)?_c('div',{staticClass:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$t('resetPass.enter_new'))+" "+_vm._s(_vm.$t('login.weak_password'))+" ")]),_c('div',{staticClass:"form-floating"},[((_vm.passwordType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"rounded-pill mt-4",class:[
                        'form-control',
                        _vm.password === '' || !_vm.formErrors.password ? '' : 'is-invalid' ],attrs:{"id":"newPassword","placeholder":_vm.$t('resetPass.new'),"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.passwordType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"rounded-pill mt-4",class:[
                        'form-control',
                        _vm.password === '' || !_vm.formErrors.password ? '' : 'is-invalid' ],attrs:{"id":"newPassword","placeholder":_vm.$t('resetPass.new'),"type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"rounded-pill mt-4",class:[
                        'form-control',
                        _vm.password === '' || !_vm.formErrors.password ? '' : 'is-invalid' ],attrs:{"id":"newPassword","placeholder":_vm.$t('resetPass.new'),"type":_vm.passwordType},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('label',{attrs:{"for":"newPassword"}},[_vm._v(_vm._s(_vm.$t('resetPass.new')))]),_c('span',{class:['fa', 'fa-fw', 'field-icon', _vm.togglePasswordClass],on:{"click":function($event){return _vm.togglePasswordType('password')}}}),(_vm.formErrors.password)?_c('div',{staticClass:"invalid-tooltip",domProps:{"innerHTML":_vm._s(_vm.formErrors.password)}}):_vm._e()]),_c('div',{staticClass:"form-floating"},[((_vm.confirmPasswordType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm_password),expression:"confirm_password"}],staticClass:"rounded-pill mt-4",class:[
                        'form-control',
                        _vm.password === '' || !_vm.formErrors.confirm_password ? '' : 'is-invalid' ],attrs:{"id":"consfirmNewPassword","placeholder":_vm.$t('resetPass.confirm_new'),"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.confirm_password)?_vm._i(_vm.confirm_password,null)>-1:(_vm.confirm_password)},on:{"change":function($event){var $$a=_vm.confirm_password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.confirm_password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.confirm_password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.confirm_password=$$c}}}}):((_vm.confirmPasswordType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm_password),expression:"confirm_password"}],staticClass:"rounded-pill mt-4",class:[
                        'form-control',
                        _vm.password === '' || !_vm.formErrors.confirm_password ? '' : 'is-invalid' ],attrs:{"id":"consfirmNewPassword","placeholder":_vm.$t('resetPass.confirm_new'),"type":"radio"},domProps:{"checked":_vm._q(_vm.confirm_password,null)},on:{"change":function($event){_vm.confirm_password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm_password),expression:"confirm_password"}],staticClass:"rounded-pill mt-4",class:[
                        'form-control',
                        _vm.password === '' || !_vm.formErrors.confirm_password ? '' : 'is-invalid' ],attrs:{"id":"consfirmNewPassword","placeholder":_vm.$t('resetPass.confirm_new'),"type":_vm.confirmPasswordType},domProps:{"value":(_vm.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirm_password=$event.target.value}}}),_c('label',{attrs:{"for":"consfirmNewPassword"}},[_vm._v(_vm._s(_vm.$t('resetPass.confirm_new')))]),_c('span',{class:['fa', 'fa-fw', 'field-icon', _vm.toggleConfirmPasswordClass],on:{"click":function($event){return _vm.togglePasswordType('confirm_password')}}}),(_vm.formErrors.confirm_password)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" "+_vm._s(_vm.formErrors.confirm_password)+" ")]):_vm._e()]),_c('div',{staticClass:"mt-4"},[_c('button',{staticClass:"btn btn-primary btn-lg w-100 rounded-pill",attrs:{"type":"submit","disabled":!_vm.password || !_vm.confirm_password || !!Object.keys(_vm.formErrors).length}},[_vm._v(" "+_vm._s(_vm.$t('resetPass.reset'))+" ")])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }