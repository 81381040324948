
import Vue from 'vue';
import Component from 'vue-class-component';
import axios from 'axios';

@Component({
    name: 'SuccessPage',
    components: {},
})
export default class SuccessPage extends Vue {
    transactionId?: string = '';
    depositId?: string = '';
    user_id?: string = '';

    async mounted() {
        this.transactionId = (this.$route.query.ac_transfer as string | undefined) || '';
        this.depositId = (this.$route.query.ac_order_id as string | undefined) || '';
        this.user_id = (this.$route.query.user_id as string | undefined) || '';
        await axios
            .get(
                `/advCashHandlers/payment/success?ac_transfer=${this.transactionId}&ac_order_id=${this.depositId}&user_id=${this.user_id}`
            )
            .then(() => {
                this.$router.push(`/dashboard/${this.user_id}`);
            })
            .catch(() => {
                this.$router.push(`/dashboard/${this.user_id}`);
            });
        // try {
        //     const userDepositRef = firebase
        //         .firestore()
        //         .doc(`users/${this.user_id}/deposit/${this.depositId}`);
        //     const allDepositRef = firebase.firestore().doc(`_priv/deposits/all/${this.depositId}`);
        //     const pendingDepositRef = firebase
        //         .firestore()
        //         .doc(`_priv/deposits/pending/${this.depositId}`);

        //     await firebase.firestore().runTransaction(async transaction => {
        //         await transaction.set(
        //             userDepositRef,
        //             { transactionId: this.transactionId },
        //             { merge: true }
        //         );
        //         await transaction.set(
        //             allDepositRef,
        //             { transactionId: this.transactionId },
        //             { merge: true }
        //         );
        //         await transaction.set(
        //             pendingDepositRef,
        //             { transactionId: this.transactionId },
        //             { merge: true }
        //         );
        //     });
        // } catch {}
        // this.$router.push(`/dashboard/${this.user_id}`);
    }
}
