






// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
class Referral extends Vue {
    async mounted() {
        const refID = this.$route.params.referrer;

        if (!localStorage.ref && refID) {
            localStorage.setItem('ref', refID);
        }

        this.$router.push({ path: '/' });
    }
}

export default Referral;
