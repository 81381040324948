

































































































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import VeriTiseText from '@/components/VeriTiseText.vue';
import { DECIMALS } from '../../../constants';
import Decimals from 'decimal.js';
import Decimal from 'decimal.js';
import firebase from 'firebase';
import axios from 'axios';

declare var bootstrap: any;

@Component({
    components: {
        VeriTiseText,
    },
    props: {
        transaction: Object,
    },
    watch: {
        async transaction() {
            this.loading = true;

            const idToken = await firebase.auth().currentUser!.getIdToken();
            await axios
                .get(`/backendApis/backend/refund-info`, {
                    params: {
                        transaction_id: this.transaction.transaction_id,
                    },
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                })
                .then(r => {
                    this.userData = r.data.data.user;
                    this.transactionFullData = r.data.data.transaction;
                })
                .finally(() => (this.loading = false));
        },
    },
})
export default class showRefundModal extends Vue {
    modal: any;
    loading = false;
    loadingRefund = false;
    userData = null;
    transactionFullData = null;

    async mounted() {
        this.modal = new bootstrap.Modal(this.$refs.showModal, {
            backdrop: 'static',
            keyboard: false,
        });
    }

    async startRefund() {
        const idToken = await firebase.auth().currentUser!.getIdToken();
        this.loadingRefund = true;

        axios
            .post(
                `/backendApis/backend/refund-start`,
                {
                    transaction_id: this.transactionFullData.transaction_id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                }
            )
            .then(r => {
                console.log(r);
                this.hide();
            })
            .finally(() => (this.loadingRefund = false));
    }

    async modalShow() {
        this.modal.show();
    }

    roundAmount(amount: number | string | undefined, token: keyof typeof DECIMALS) {
        return new Decimals(amount ?? 0)
            .toDecimalPlaces(
                DECIMALS[token],
                token === 'vts' ? Decimal.ROUND_UP : Decimal.ROUND_HALF_UP
            )
            .toString();
    }

    async hide() {
        this.$emit('closeModal');
        this.modal.hide();
    }
}
