


















































































































































// @ is an alias to /src
import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import VeriTiseText from '@/components/VeriTiseText.vue';
import { DepositStatus } from '@/sharedTypes/base';
import axios from 'axios';
import { CurLadder, DepositRequest, DepositRequest_Filled } from '../../../sharedTypes';
import { Refs } from '@/utils/refs';
import { AdminTransferItem } from '../../../sharedTypes/AdminTransferItem';
import { DECIMALS } from '../../../constants';
import Decimals from 'decimal.js';
import Decimal from 'decimal.js';

declare var bootstrap: any;

@Component({
    components: {
        VeriTiseText,
    },
})
export default class Dashboard extends Vue {
    private searchRes: AdminTransferItem[] = [];
    private curLadder: CurLadder | null = null;
    private loading = false;
    private unsubs: any[] = [];
    private results: any[] = [];
    modal: any;
    completeD: AdminTransferItem | null = null;
    private modalReceivedAmount = null;
    private loadingComplete = false;

    async mark(item: AdminTransferItem, status: DepositStatus, paidInFact = null) {
        this.loading = true;

        const idToken = await firebase.auth().currentUser!.getIdToken();
        await axios
            .post(
                `/backendApis/backend/wire-transfers/${item.dID}/${status}/${paidInFact ?? '0'}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                }
            )
            .catch(e => {
                console.log(e);
                this.closeModal();
                this.loadingComplete = false;
            });

        await this.closeModal();
        this.loadingComplete = false;
        await this.search();
    }

    async complete() {
        this.loadingComplete = true;

        if (this.completeD) {
            await this.mark(this.completeD, 'completed', this.modalReceivedAmount);
        }
    }

    async copyText(e) {
        const reference = e.target.innerHTML;
        e.target.innerHTML = '<u>' + this.$t('copied') + '!</u>';
        setTimeout(() => {
            e.target.innerHTML = reference;
        }, 800);
    }

    prepareCrossLadderInfo(data: DepositRequest | DepositRequest_Filled | undefined | null) {
        if (!data?.crossLadderInfo) {
            return [];
        }

        return data.crossLadderInfo.map(cross => ({
            ...cross,
            price:
                Math.round(
                    cross.price * (1 - ('discount' in data ? data?.discount ?? 0 : 0)) * 100
                ) / 100,
        }));
    }

    async mounted() {
        this.modal = new bootstrap.Modal(this.$refs.completeModal, {
            backdrop: 'static',
            keyboard: false,
        });

        this.unsubs.push(
            Refs.info('curLadderInfo').onSnapshot(next => {
                this.curLadder = next.data()!;
                this.$forceUpdate();
            })
        );

        await this.search();
    }

    async search() {
        this.loading = true;

        const idToken = await firebase.auth().currentUser!.getIdToken();
        this.searchRes = await axios
            .get('/backendApis/backend/wire-transfers', {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            })
            .then(r => r.data);
        this.loading = false;
    }

    async showCompleteModal(d) {
        this.completeD = d;
        this.modal.show();
    }

    async closeModal() {
        this.modalReceivedAmount = null;
        this.completeD = null;
        this.modal.hide();
    }

    roundAmount(amount: number | string | undefined, token: keyof typeof DECIMALS) {
        return new Decimals(amount ?? 0)
            .toDecimalPlaces(
                DECIMALS[token],
                token === 'vts' ? Decimal.ROUND_UP : Decimal.ROUND_HALF_UP
            )
            .toString();
    }

    destroyed() {
        this.unsubs.forEach(a => a());
    }
}
