<template>
    <div class="col-md-6 col-s-12 text-center">
        <h1>{{ $t('emailVerification.confirmation') }}</h1>
        <div v-if="success && !error" class="alert alert-success mt-4">
            {{ $t('emailVerification.verified') }}
        </div>
        <div v-if="error" class="alert alert-danger mt-4">{{ error }}</div>
        <br />
        <button
            v-if="success && !error"
            class="btn btn-primary btn-lg w-100 rounded-pill btn-email"
            @click="
                signIn();
                $analytics.logEvent('LandingEmailConfirmationLinkResend');
            "
        >
            {{ $t('emailVerification.sign_in') }}
        </button>
    </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';
import { EventBus } from '@/utils/bus';

@Component({
    props: {
        code: String,
    },
})
export default class EmailVerification extends Vue {
    success = false;
    error = '';

    async mounted() {
        const { code } = this.$props;

        if (code) {
            try {
                await firebase.auth().applyActionCode(code);

                const user = await firebase.auth().currentUser;

                if (user) {
                    await user.reload();
                }

                this.success = true;
                this.error = '';
            } catch (e) {
                this.error = e.message;
            }
        }
    }

    signIn() {
        EventBus.$emit('showSignInModal');
    }
}
</script>
