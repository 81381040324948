



























































































































import Vue from 'vue';
import i18n from '@/utils/i18n';
import Component from 'vue-class-component';
import LoginComponent from '@/components/Login.vue';
import firebase from 'firebase';
import { EventBus } from '@/utils/bus';
import { Refs } from '@/utils/refs';
import moment from 'moment';
import AnchorRouterLink from 'vue-anchor-router-link';

@Component({
    name: 'Header',
    components: { LoginComponent, AnchorRouterLink },
    watch: {
        selectedLang({ label }) {
            i18n.locale = label;
            sessionStorage.locale = label;
            this.$analytics.logEvent('LandingHeaderDropdownSelectLanguage', {
                language: label,
            });
        },
        $route(to) {
            const curUserUid = firebase.auth()?.currentUser?.uid;

            if (to.name?.toLowerCase() === 'dashboard' && curUserUid) {
                Refs.forUser(curUserUid)
                    .wp.data.get()
                    .then(r => r.data()?.country || '')
                    .then(country => {
                        if (!country) {
                            EventBus.$emit('showSetCountryModal');
                        }
                    });
            }
        },
    },
})
export default class Header extends Vue {
    private modal: any;
    private isLoggedin = false;
    private isAdmin = false;
    isSignIn = false;
    private options = [
        {
            label: 'ARA',
            value: 'ar',
        },
        {
            label: 'CHI',
            value: 'ch',
        },
        {
            label: 'DUT',
            value: 'nl',
        },
        {
            label: 'ENG',
            value: 'gb',
        },
        {
            label: 'FRE',
            value: 'fr',
        },
        {
            label: 'GER',
            value: 'de',
        },
        {
            label: 'HIN',
            value: 'in',
        },
        {
            label: 'ITA',
            value: 'it',
        },
        {
            label: 'JPN',
            value: 'jp',
        },
        {
            label: 'KOR',
            value: 'kr',
        },
        {
            label: 'POR',
            value: 'pt',
        },
        {
            label: 'RUS',
            value: 'ru',
        },
        {
            label: 'SPA',
            value: 'es',
        },
    ];
    private defaultLang = sessionStorage.locale || 'ENG';
    private selectedLang = this.options.find(op => op.label === this.defaultLang);
    timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
    };

    async mounted() {
        firebase.auth().onAuthStateChanged(async user => {
            if (
                !user?.emailVerified &&
                !user?.phoneNumber &&
                !user?.providerData?.some(item => item?.providerId === 'facebook.com')
            ) {
                this.isLoggedin = false;
                firebase.auth().signOut();
            } else {
                this.isLoggedin = true;
            }

            user?.getIdTokenResult()
                .then(idTokenResult => {
                    this.isAdmin = !!idTokenResult.claims.admin;
                })
                .catch(() => (this.isAdmin = false));
        });
    }

    created() {
        setInterval(() => {
            var to = moment('2022-09-19');
            var dif = to.diff(moment());

            this.timeLeft = {
                days: parseInt(moment.duration(dif, 'millisecond').asDays()),
                hours: moment.duration(dif, 'millisecond').hours(),
                minutes: moment.duration(dif, 'millisecond').minutes(),
            };
        }, 1000);
    }

    signIn() {
        EventBus.$emit('showSignInModal');
        this.$analytics.logEvent('LandingHeaderButtonSignIn');
    }

    signUp() {
        EventBus.$emit('showSignUpModal');
        this.$analytics.logEvent('LandingHeaderButtonGetStarted');
    }

    goToDashboard() {
        this.$router.push({ path: `/dashboard/${firebase.auth().currentUser!.uid}` });
    }

    async logout() {
        this.$analytics.logEvent('DashboardHeaderLinkLogOut');
        await firebase.auth().signOut();
        this.$router.push({ path: '/' });
    }

    whitepaper() {
        if (this.selectedLang.label === 'FRE') {
            return '/whitepaper/Veritise-Technical-White-Paper_FRE.pdf';
        }

        return '/whitepaper/Veritise-Technical-White-Paper.pdf';
    }
}
