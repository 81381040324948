


























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    props: {
        number: Number,
        title: String,
        soon: Boolean,
    },
    watch: {
        number(v) {
            this.setNumber(v);
        },
    },
})
export default class GenericInfoBox extends Vue {
    private number;
    private title;
    private innerNumber = 0;
    private blockHovered = false;

    async setNumber(v) {
        this.innerNumber = v;
    }

    async mounted() {
        await this.setNumber(this.number);
    }
}
