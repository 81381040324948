


















































































import Vue from 'vue';
import Component from 'vue-class-component';
import firebase from 'firebase';

@Component({
    name: 'ResetPasswordForm',
    watch: {
        password(val: string) {
            if (val.length < 6 || val.length > 16) {
                this.formErrors = {
                    ...this.formErrors,
                    password: this.$t('login.password_length'),
                };
                return;
            }
            if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(val)) {
                this.formErrors = {
                    ...this.formErrors,
                    password: this.$i18n.t('login.weak_password'),
                };
                return;
            } else {
                delete this.formErrors.password;

                if (this.password === this.confirm_password) {
                    delete this.formErrors.confirm_password;
                } else {
                    this.formErrors = {
                        ...this.formErrors,
                        confirm_password: this.$i18n.t('login.confirm_password_invalid'),
                    };
                }
            }
            this.isDirty = true;
        },
        confirm_password(val: string) {
            if (val !== this.password) {
                this.formErrors = {
                    ...this.formErrors,
                    confirm_password: this.$i18n.t('login.confirm_password_invalid'),
                };
            } else {
                delete this.formErrors.confirm_password;
            }
            this.isDirty = true;
        },
    },
    props: {
        code: String,
    },
})
export default class SetNewPasswordForm extends Vue {
    password = '';
    isValid = false;
    isDirty = false;
    success = false;
    submitError = '';
    formErrors = {};
    private passwordType = 'password';
    private togglePasswordClass = 'fa-eye';
    private toggleConfirmPasswordClass = 'fa-eye';
    private confirmPasswordType = 'password';
    private confirm_password = '';

    togglePasswordType(target): void {
        if (target === 'password') {
            if (this.passwordType === 'password') {
                this.passwordType = 'text';
                this.togglePasswordClass = 'fa-eye-slash';
            } else {
                this.passwordType = 'password';
                this.togglePasswordClass = 'fa-eye';
            }
        } else {
            if (this.confirmPasswordType === 'password') {
                this.confirmPasswordType = 'text';
                this.toggleConfirmPasswordClass = 'fa-eye-slash';
            } else {
                this.confirmPasswordType = 'password';
                this.toggleConfirmPasswordClass = 'fa-eye';
            }
        }
    }

    onSubmit() {
        this.submitError = '';
        this.$analytics.logEvent('PasswordResetButtonConfirmNewPassword');
        firebase
            .auth()
            .confirmPasswordReset(this.$props.code, this.password)
            .then(() => {
                this.success = true;
                this.submitError = '';
            })
            .catch(e => {
                this.success = false;
                this.submitError = e;
            });
    }
}
