




































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    props: {
        totalVerified: Number,
        totalTransactions: Number,
        totalReferrals: Number,
    },
})
export default class Top10 extends Vue {
    private totalVerified: number | undefined;
    private totalTransactions: number | undefined;
    private totalReferrals: number | undefined;

    private formatNumber(what: number) {
        return what.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
}
